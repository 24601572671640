import React, { Component } from 'react';
import { Basket } from './Basket';
import { NavMenu } from './NavMenu';

export class Footer extends Component {
    displayName = Footer.name;

    constructor(props) {
        super(props);
        this.addBasket = this.addBasket.bind(this);
    }

    addBasket = () => {
        let properties = this.props.properties;
        let selectedProperties = this.props.selectedProperties;

        if (this.props.section === 1)
            return (
                <Basket
                    removeSelectedProperty={this.props.removeSelectedProperty}
                    properties={properties}
                    selectedProperties={selectedProperties}
                    availableProperties={this.props.availableProperties}
                    vendors={this.props.vendors}
                />);
        else
            return null;
    }

    render() {
        let updateTerms = this.props.updateTerms;
        let next = this.props.next;
        let previous = this.props.previous;
        let selectedProperties = this.props.selectedProperties;

        return (
            <section className="g-footer">
                {this.addBasket()}
                <NavMenu
                    termsAgreed={this.props.termsAgreed}
                    updateTerms={updateTerms}
                    section={this.props.section}
                    next={next}
                    previous={previous}
                    implementRecap={this.props.implementRecap}
                    canContinue={this.props.canContinue}
                    permittedLicenseTypes={this.props.permittedLicenseTypes}
                    newLicenseTypes={this.props.newLicenseTypes}
                    validateDetails={this.props.validateDetails}
                    selectedProperties={selectedProperties}
                />
                <p className="g-footer__copyright">Ver {process.env.REACT_APP_VERSION} - Copyright &copy; 2019 to {new Date().getFullYear()} 51Degrees</p>
            </section>
        );
    }
}