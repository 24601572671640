import React, { Component } from 'react';
import { HTML } from './HTML';

export class ClientSideImplementation extends Component {
    displayName = ClientSideImplementation.name;

    constructor(props) {
        super(props);
        this.state = {
            active: this.props.active
        };

        this.htmlRef = React.createRef();
        let url = "https://cloud.51degrees.com";
        let headerNames = [
            'ch-ua-full-version-list',
            'ch-ua-model',
            'ch-ua-platform',
            'ch-ua-platform-version'
        ];
        let headerValue = '';
        let elementValue = '';
        let chHeaderValue = '';
        headerNames.forEach((feat) => {
            if (headerValue.length != 0) {
                headerValue += ', ';
            }
            headerValue += feat + '=(self "' + url + '")';
        });
        headerNames.forEach((feat) => {
            if (elementValue.length != 0) {
                elementValue += '; ';
            }
            elementValue += 'sec-' + feat + ' ' + url;
        });
        headerNames.forEach((feat) => {
            if (chHeaderValue.length != 0) {
                chHeaderValue += ', ';
            }
            chHeaderValue += 'sec-' + feat;
        })
        this.permissionsHeader = "Permissions-Policy: " + headerValue;
        this.permissionsElement = '<meta http-equiv="Delegate-CH" content="' + elementValue + '"/>';
        this.chHeader = "Accept-CH:" + chHeaderValue;

    }

    copy = (data) => {
        var dummy = document.createElement('textarea');
        document.body.appendChild(dummy);
        dummy.value = data;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert('Copied!');
    }

    copyHeader = () => {
        this.copy(this.permissionsHeader);
    }

    copyChHeader = () => {
        this.copy(this.chHeader);
    }

    copyElement = () => {
        this.copy(this.permissionsElement);
    }

    runCodePrettify = () => {
        const { runCodePrettify } = this.props;
        runCodePrettify();
    }

    handleClick = (e) => {
        e.preventDefault();
        const { handleClick } = this.props;
        handleClick('');
    };

    scrollToTop = () => {
        if (this.refs.scroller) {
            this.refs.scroller.scrollTop = 0;
        }
    };

    scrollToMyRef = (e, ref) => {
        e.preventDefault();
        ref.current.scrollIntoView();
    }

    render() {
        let classes = "g-panel g-panel--75";

        if (this.props.active) {
            classes += " is-active";
        }

        return (
            <div className={classes}>
                <header className="c-category-header">
                    <a href="#" className="c-category-header__link is-reversable" onClick={this.handleClick}>Back</a> / Client side
                    </header>
                <article className="g-info" ref="scroller">
                    <h2 className="g-info__title">Client side implementation</h2>
                    <nav className="c-sub-nav">
                        <h3 className="c-sub-nav__heading">On this page</h3>
                        <p>Understand how physical screen size, device price and page performance impact conversion and goal completion.</p>
                        <ul className="c-sub-nav__list">
                            <li className="c-sub-nav__link"><a href="#html" className="c-sub-nav__item" onClick={(e) => { this.scrollToMyRef(e, this.htmlRef); }}>HTML</a></li>
                        </ul>
                    </nav>
                    <h2 className="g-info__subheading">Client Hint Permissions</h2>
                    <p>
                        Browsers will not send all client-hints to third parties by default.
                        You will need to let the browser know that it is allowed to send these
                        to our cloud service by including a 'Delegate-CH' directive in the
                        <em>head</em> of the HTML document:
                    </p>
                    <div className="c-code">
                        <header className="c-code__header" id="example3">
                            <h2 className="c-code__heading">Permissions Element</h2>
                            <a href="#" className="c-copy-clipboard" onClick={this.copyElement}><img src="/images/icon-clipboard.svg" alt="Copy to clipboard" /></a>
                        </header>
                        <code className="c-code__block">{this.permissionsElement}</code>
                    </div>
                    <p>
                        Alternatively, you can use the Permissions-Policy and Accept-CH HTTP
                        headers to achieve the same result:
                    </p>
                    <div className="g-info__description">
                        <div className="c-code">
                            <header className="c-code__header" id="example1">
                                <h2 className="c-code__heading">Permissions Header</h2>
                                <a href="#" className="c-copy-clipboard" onClick={this.copyHeader}><img src="/images/icon-clipboard.svg" alt="Copy to clipboard" /></a>
                            </header>
                            <code className="c-code__block">{this.permissionsHeader}</code>
                        </div>
                        <div className="c-code">
                            <header className="c-code__header" id="example2">
                                <h2 className="c-code__heading">Accept-CH Header</h2>
                                <a href="#" className="c-copy-clipboard" onClick={this.copyChHeader}><img src="/images/icon-clipboard.svg" alt="Copy to clipboard" /></a>
                            </header>
                            <code className="c-code__block">{this.chHeader}</code>
                        </div>
                    </div>

                    <HTML
                        refProp={this.htmlRef}
                        id="html"
                        properties={this.props.properties}
                        selectedProperties={this.props.selectedProperties}
                        hiddenProperties={this.props.hiddenProperties}
                        resource={this.props.resource}
                        examples={this.props.examples}
                        runCodePrettify={this.runCodePrettify}
                        permissionsElement={this.permissionsElement}
                    />
                    <h2 className="g-info__subheading">Benefits of client side implementations</h2>

                    <div className="g-info__description">
                        <p><em>Richer Context:</em> Understand how physical screen size, device price and page performance impact conversion and goal completion.</p>
                        <p><em>User Experience:</em> Discover insights, test, and learn to continually improve with richer data from Enhanced Analytics.</p>
                        <p><em>Collaboration:</em> Easy to integrate with existing business intelligence tools including Google Analytics, Omniture, Coremetrics and Webtrends.</p>
                    </div>
                    <a className="c-top-link hide" href="#" id="js-top" onClick={(e) => { e.preventDefault(); this.scrollToTop(); }}>
                        <img src="/images/back-to-top.svg" alt="Back to top" className="c-top-link__image" />
                        <span className="c-top-link__label">Back to top</span>
                    </a>
                </article>
            </div>);
    }
}
