import React, { Component } from 'react';
import { Output } from './../Output';

export class HTML extends Component {
    displayName = HTML.name;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { runCodePrettify } = this.props;
        runCodePrettify();
    }

    copyCode = (e) => {
        e.preventDefault();
        var dummy = document.createElement('textarea');
        document.body.appendChild(dummy);
        dummy.value = this.getCode();
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert('Copied!');
    };

    getCode = () => {
        let properties = this.props.properties;

        let delayedProperties = properties.filter(prop => prop.DelayExecution).map(prop => prop.Id.toLowerCase());
        let delayedDataKeys = [];
        let delayedLines = [];
        let lines = [];

        this.props.selectedProperties
            .filter(prop => prop.startsWith("hardware.Profiles") === false &&
                this.props.hiddenProperties.indexOf(prop.toLowerCase()) === -1)
            .forEach((selectedProperty) => {
            let property = properties.find(prop => { return prop.Id.toUpperCase() === selectedProperty.toUpperCase(); });
            if (property) {
                let delayed = false;
                if (property.EvidenceProperties && property.EvidenceProperties.length > 0) {
                    property.EvidenceProperties.forEach(prop => {
                        if (delayedProperties.indexOf(property.VendorId + "." + prop) !== -1) {
                            delayed = true;
                            if (delayedDataKeys.indexOf(property.VendorId) === -1)
                                delayedDataKeys.push(property.VendorId);
                        }
                    });
                }

                if (delayed)
                    delayedLines.push(`addText("${property.Name}: " + data.${property.ValuePath});`);
                else
                    lines.push(`addText("${property.Name}: " + data.${property.ValuePath});`);
            }
            else
                return;
        });

        let hardwareProp = this.props.selectedProperties.indexOf("hardware.Profiles") !== -1;
        let hardwareProps = this.props.selectedProperties.filter(prop => prop.startsWith("hardware.Profiles."));
        if (hardwareProp && hardwareProps.length > 0) {
            lines.push(" ");
            lines.push("data.hardware.profiles.forEach(function(profile) {");
            lines.push('addText("");');
            hardwareProps.map((selectedProperty) => {
                let property = selectedProperty.split('.').slice(-1)[0];
                lines.push(`addText("${property}: " + profile["${property}"]);`);
            });
            lines.push("});");
        }

        let code = `<!DOCTYPE html>
<html>
    <head>
        ${this.props.permissionsElement}
    </head>
    <body>
        <h1>51Degrees cloud example</h1>
        <button type="button" onclick="buttonClicked()">Use my location</button>
        <div id="htmlExampleOutput"></div>
    </body>
    <script async src="${this.props.resource.url}"></script>
    <script type="text/javascript">
        window.onload = function() {
            fod.complete(function (data) {
                // Fetch the properties from the JSON response and write to the page.
                ${lines.filter(line => line !== "").join('\r\n                ')}
            });
        }`;
    
        if (delayedLines.length > 0) {
            code += `
    
        buttonClicked = function() {
            fod.complete(function (data) {
                ${delayedLines.join('\r\n            ')}
            }, '${delayedDataKeys.join(",")}');
        }`;
            }
    
            code +=  `
    
        addText = function(text) {
            document.getElementById("htmlExampleOutput").innerHTML+=text + "<br>";
        }
    </script>
</html>`;

        let formatted = code;
        return formatted;
    };

    scrollToTop = () => {
        if (this.refs.scroller) {
            this.refs.scroller.scrollTop = 0;
        }
    };

    render() {
        return (
            <div id={this.props.id} ref={this.props.refProp}>
                <h2 className="g-info__subheading">HTML</h2>
                <article className="g-example-block">
                    <div className="c-code">
                        <header className="c-code__header" id="example1">
                            <h2 className="c-code__heading">Example HTML code</h2>
                            <a href="#" className="c-copy-clipboard" onClick={this.copyCode}><img src="/images/icon-clipboard.svg" alt="Copy to clipboard" /></a>
                        </header>
                        <div className="c-code__block">
                            <pre className="prettyprint lang-html">{this.getCode()}</pre>
                        </div>
                    </div>
                    <Output examples={this.props.examples} selectedProperties={this.props.selectedProperties} />
                </article>
            </div>);
    }
}
