import React, { Component } from 'react';

export class ShareNav extends Component {
    displayName = ShareNav.name

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="g-footer__navigation">
                <div className="g-footer__previous">
                    <a href="#" className="b-btn b-btn--large b-btn--secondary" onClick={this.props.previous}><img src="/images/icon-arrow-left-black.svg" className="b-btn__icon" alt="Previous" /><em>Previous step:</em>&nbsp;<strong>Implement</strong></a>
                </div>
            </div>
        );
    }
}