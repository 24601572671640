import React, { Component } from 'react';

import { SearchItem } from './SearchItem';

export class Search extends Component {
    displayName = Search.name;

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            filterText: ''
        };
    }

    handleUserInput = (event) => {
        this.setState({ filterText: event.target.value });
    }

    onFocus = () => {
        this.props.setFocus(true);
    }

    handleClick = (item) => {
        const { onSearch } = this.props;
        onSearch(item);
        this.props.setFocus(false);
    }

    render() {

        var items = [];
        
        if (this.state.filterText) {
            var searchTerm = this.state.filterText.toLowerCase();

            // Get the first 20 items that include the search term.
            const matches = this.props.properties.filter(i =>
                i.Name.toLowerCase().includes(searchTerm)).slice(0, 20);

            // Deduplicate these matches as sometimes the same property appears
            // in multiple categories.
            const dedup = matches.filter((item,
                index) => matches.indexOf(item) === index);

            // Update the array with the SearchItem components.
            items = dedup.map((item) => {
                    var vendor = this.props.vendors.find((vend) => {
                        return vend.map === item.VendorId;
                    });
                    return <SearchItem onClick={this.handleClick} value={item.Name} vendor={vendor} />;
                });
        }

        var classes = "c-search is-openable";

        if (this.props.focused)
            classes += " is-opened";
        
        return (
            <div className={classes}>
                <form className="c-search__form">
                    <div className="b-form-group b-form-group--with-icon">
                        <label htmlFor="inputSearch" className="b-text--hidden">Search</label>
                        <img src="/images/icon-search.png" className="b-icon" alt="Search" />
                        <input
                            type="text"
                            className="c-search__input"
                            id="inputSearch"
                            placeholder="Start typing to find a property"
                            aria-label="Search"
                            onChange={this.handleUserInput.bind(this)}
                            value={this.state.filterText}
                            onFocus={this.onFocus}
                        />
                    </div>
                </form>
                <div className="c-search__content">
                    <ul className="c-search__list">
                        {items}
                    </ul>
                </div>
            </div>
        );
    }
}

