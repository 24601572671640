import React, { Component } from 'react';

export class ImplementNav extends Component {
    displayName = ImplementNav.name;

    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.implementRecap !== true) {
            return (
                <div className="g-footer__navigation">
                    <div className="g-footer__previous">
                        <a href="#" className="b-btn b-btn--large b-btn--secondary" onClick={this.props.previous}><img src="/images/icon-arrow-left-black.svg" className="b-btn__icon" alt="Previous" /><em>Previous step:</em>&nbsp;<strong>Details</strong></a>
                    </div>
                    <div className="g-footer__next">
                        <a href="#" className="b-link b-link--unstyled" onClick={this.props.next}>Finished? <img src="/images/icon-arrow-right-white.svg" className="b-btn__icon" alt="Next" /></a>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}