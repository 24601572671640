import React, { Component } from 'react';

import { BasketItem } from './BasketItem';

export class Basket extends Component {
    displayName = Basket.name;

    constructor(props) {
        super(props);

        this.state = {
            basketOpen: false
        };
    }

    toggleBasket = (e) => {
        e.preventDefault();
        this.setState({ basketOpen: !this.state.basketOpen });
    }

    removeFromBasket = (item) => {
        const { removeSelectedProperty } = this.props;
        removeSelectedProperty(item);
    }

    render() {
        var classes = "g-basket is-openable";
        var selected = this.props.selectedProperties;

        if (this.state.basketOpen)
            classes += " is-opened";

        var props = this.props.properties.filter(p => selected.indexOf(p.Id) !== -1);

        return (
            <section className={classes}>
                <a href="#" className="g-basket__status" onClick={this.toggleBasket}>
                    Selected properties <span className="g-basket__count">{props.length}</span>
                </a>
                <div className="g-basket__content">
                    <ul className="g-basket__properties">
                        {props.map((property, i) => {
                            return (
                                <BasketItem
                                    key={i}
                                    property={property}
                                    availableProperties={this.props.availableProperties}
                                    vendors={this.props.vendors}
                                    onClick={this.removeFromBasket.bind(this)}
                                />);
                            })
                        }
                    </ul>
                </div>
            </section>
        );
    }
}