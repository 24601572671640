import React, { Component } from 'react';

export class SelectableCard extends Component {
    displayName = SelectableCard.name;

    constructor(props) {
        super(props);
    }

    onSelected = () => {
        const { onSelected } = this.props;
        if (this.props.disabled === false &&
            this.props.selected === false &&
            onSelected != null) {
            onSelected(this.props.id);
        }
    }

    displayTick() {
        if (this.props.selected) {
            return (
                <img src="/images/check-lg-on.png" className="b-icon tick" alt="Selected" />
            );
        }
    }

    render() {
        var selected = this.props.selected;
        var disabled = this.props.disabled;

        var cardClasses = 'c-card';
        if (disabled) {
            cardClasses = cardClasses + ' c-card--disabled';
        }
        else if (selected) {
            cardClasses = cardClasses + ' c-card--selected';
        }

        return (
            <article className={cardClasses} onClick={this.onSelected}>
                {this.props.children}
            </article>
        );
    }
}