import React, { Component } from 'react';
import { ReferrerImplementation } from './ReferrerImplementation';

export class Referrer extends Component {
    displayName = Referrer.name;

    constructor(props) {
        super(props);
    }

    name = 'Referrer';

    handleClick = (e) => {
        e.preventDefault();
        const { handleClick } = this.props;
        handleClick(this.name);
    }

    render() {
        let _active = this.props.active === this.name;

        let classes = "c-category__link is-revealable";

        if (_active)
            classes += " is-active";

        return (
            <li key="referrer" className="g-panel__item">
                <a href="#" className={classes} onClick={this.handleClick}>Resource Key</a>
                <ReferrerImplementation
                    active={_active}
                    selectedProperties={this.props.selectedProperties}
                    handleClick={this.props.handleClick}
                    resource={this.props.resource}
                    referer={this.props.referer}
                />
            </li>
        );
    }
}