import React, { Component } from 'react';
import { Output } from './../Output';

export class DotNet extends Component {
    displayName = DotNet.name;

    constructor(props) {
        super(props);
    }

    copyCode = (e) => {
        e.preventDefault();
        this.copy(this.getCode());
    };

    copy = (data) => {
        var dummy = document.createElement('textarea');
        document.body.appendChild(dummy);
        dummy.value = data;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert('Copied!');
    };

    getPackages = () => {
        let dataKeys = [];

        this.props.selectedProperties.forEach(prop => {
            var dataKey = prop.substring(0, prop.lastIndexOf('.'));
            if (dataKeys.indexOf(dataKey) === -1) {
                dataKeys.push(dataKey);
            }
        });

        let elements = [];
        let addedPackages = [];

        elements.push(this.props.newPackage(this.copy, 'Package Manager - Microsoft Console Logging Extensions', 'Install-Package Microsoft.Extensions.Logging.Console', 'ms-logging-console'));

        dataKeys.forEach(dataKey => {
            switch (dataKey) {
                case 'device':
                case 'hardware.Profile':
                    if (addedPackages.indexOf("device") === -1) {
                        addedPackages.push("device");
                        elements.push(this.props.newPackage(this.copy, 'Package Manager - Device Detection', 'Install-Package FiftyOne.DeviceDetection', 'fod-dd'));
                    }
                    break;
                case 'location':
                case 'location_digitalelement':
                case 'local_countryname':
                case 'location_consolidated':
                case 'neighbouring_countries':
                case 'country_list':
                    if (addedPackages.indexOf("location") === -1) {
                        addedPackages.push("location");
                        elements.push(this.props.newPackage(this.copy, 'Package Manager - Geo Location', 'Install-Package FiftyOne.GeoLocation', 'fod-geo'));
                    }
                    break;
                default:
                    break;
            }
        });

        return elements;
    }

    addReverseGeoLocationEvidence = (evidenceList) => {
        var lat = 'data.AddEvidence("query.51D_Pos_latitude", "51.458048");';
        var lon = 'data.AddEvidence("query.51D_Pos_longitude", "-0.9822207999999999");';

        if (evidenceList.indexOf(lat) === -1) {
            evidenceList.push(lat);
        }

        if (evidenceList.indexOf(lon) === -1) {
            evidenceList.push(lon);
        }
    }

    getCode = () => {
        let addToArray = this.props.addToArray;
        let dataKeys = this.props.dataKeys;

        let imports = [];
        let engines = [];
        let addEngines = [];
        let evidence = [];
        let results = [];

        dataKeys.forEach(dataKey => {
            switch (dataKey) {
                case 'device':
                    addToArray(imports, 'using FiftyOne.DeviceDetection;');
                    addToArray(imports, 'using FiftyOne.DeviceDetection.Cloud.Data;');
                    addToArray(imports, 'using FiftyOne.DeviceDetection.Cloud.FlowElements;');
                    addToArray(imports, 'using FiftyOne.DeviceDetection.Shared;');
                    engines.push(`var deviceDetectionCloudEngine =
                new DeviceDetectionCloudEngineBuilder(_loggerFactory)
                .Build();`);
                    addEngines.push('.AddFlowElement(deviceDetectionCloudEngine)');
                    evidence.push(`data.AddEvidence(FiftyOne.Pipeline.Core.Constants.EVIDENCE_QUERY_USERAGENT_KEY, 
                "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G960U) " +
                "AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/10.1 " +
                "Chrome/71.0.3578.99 Mobile Safari/537.36");`);
                    results.push('var device = data.Get<IDeviceData>();');
                    break;
                case 'hardware.Profiles':
                    addToArray(imports, 'using FiftyOne.DeviceDetection.Cloud.Data;');
                    addToArray(imports, 'using FiftyOne.DeviceDetection.Cloud.FlowElements;');
                    addToArray(imports, 'using FiftyOne.DeviceDetection.Shared;');
                    engines.push(`var propertyKeyedEngine =
                new HardwareProfileCloudEngineBuilder(_loggerFactory)
                .Build();`);
                    addEngines.push('.AddFlowElement(propertyKeyedEngine)');
                    evidence.push('data.AddEvidence(Constants.EVIDENCE_QUERY_TAC_KEY, "35925406");');
                    evidence.push('//data.AddEvidence(Constants.EVIDENCE_QUERY_NATIVE_MODEL_KEY, "iPhone11,8");');
                    break;
                case 'location':
                    addToArray(imports, 'using FiftyOne.GeoLocation.Cloud.FlowElements;');
                    addToArray(imports, 'using FiftyOne.GeoLocation.Core;');
                    addToArray(imports, 'using FiftyOne.GeoLocation.Core.Data;');
                    engines.push(`var fodLocationCloudEngine = 
                new GeoLocationCloudEngineBuilder(_loggerFactory)
                .SetGeoLocationProvider("FiftyOneDegrees")
                .Build();`);
                    addEngines.push('.AddFlowElement(fodLocationCloudEngine)');
                    this.addReverseGeoLocationEvidence(evidence);
                    results.push('var location = data.GetFromElement(fodLocationCloudEngine);');
                    break;
                case 'location_consolidated':
                    addToArray(imports, 'using FiftyOne.GeoLocation.Cloud.FlowElements;');
                    addToArray(imports, 'using FiftyOne.GeoLocation.Core;');
                    addToArray(imports, 'using FiftyOne.GeoLocation.Core.Data;');
                    engines.push(`var consolidateLocationCloudEngine =
                new GeoLocationCloudEngineBuilder(_loggerFactory)
                .SetGeoLocationProvider("Consolidated")
                .Build();`);
                    addEngines.push('.AddFlowElement(consolidateLocationCloudEngine)');
                    results.push('var location_consolidated = data.GetFromElement(consolidateLocationCloudEngine);')
                    break;
                case 'local_countryname':
                    addToArray(imports, 'using FiftyOne.GeoLocation;');
                    addToArray(imports, 'using FiftyOne.GeoLocation.LocalCountryName.FlowElements;');
                    addToArray(imports, 'using FiftyOne.GeoLocation.LocalCountryName.Data;');
                    engines.push(`var localCountryNameEngine = 
                new LocalCountryNameEngineBuilder(_loggerFactory)
                .Build();`);
                    addEngines.push('.AddFlowElement(localCountryNameEngine)');
                    evidence.push('data.AddEvidence(Constants.EVIDENCE_LANGUAGE_ACCEPT_HEADER, "de_DE");');
                    addToArray(evidence, 'data.AddEvidence(Constants.EVIDENCE_COUNTRY_CODE, "DE");');
                    results.push('var local_countryname = data.GetFromElement(localCountryNameEngine);');
                    break;
                case 'neighbouring_countries':
                    addToArray(imports, 'using FiftyOne.GeoLocation;');
                    addToArray(imports, 'using FiftyOne.GeoLocation.NeighbouringCountries.FlowElements;');
                    addToArray(imports, 'using FiftyOne.GeoLocation.NeighbouringCountries.Data;');
                    engines.push(`var neighbouringCountriesEngine = 
                new NeighbouringCountriesEngineBuilder(_loggerFactory)
                .Build();`);
                    addEngines.push('.AddFlowElement(neighbouringCountriesEngine)');
                    addToArray(evidence, 'data.AddEvidence(Constants.EVIDENCE_COUNTRY_CODE, "DE");');
                    results.push('var neighbouring_countries = data.GetFromElement(neighbouringCountriesEngine);');
                    break;
                case 'country_list':
                    addToArray(imports, 'using FiftyOne.GeoLocation.CountryList.FlowElements;');
                    addToArray(imports, 'using FiftyOne.GeoLocation.CountryList.Data;');
                    engines.push(`var countryListEngine = 
                new CountryListEngineBuilder(_loggerFactory)
                .Build();`);
                    addEngines.push('.AddFlowElement(countryListEngine)');
                    results.push('var country_list = data.GetFromElement(countryListEngine);');
                    break;
                default:
                    break;
            }
        });

        let lines = this.props.selectedProperties
            .filter(prop =>
                prop.startsWith("hardware.Profiles") === false &&
                prop.startsWith("device") &&
                this.props.hiddenProperties.indexOf(prop.toLowerCase()) === -1)
            .map((selectedProperty, i) => {
                selectedProperty = selectedProperty.replace(/-|\//g, '');
                return `                    output.WriteLine($"${selectedProperty}: {${selectedProperty}.Value}");`
            });
        if (lines.length === 0) {
            // No device detection properties, so just add IsMobile.
            lines[0] = `                    output.WriteLine($"device.IsMobile: {device.IsMobile.Value}");`
        }

        //let hardwareProp = this.props.selectedProperties.indexOf("hardware.Profiles") !== -1;
        //let hardwareProps = this.props.selectedProperties.filter(prop => prop.startsWith("hardware.Profiles."));
        //if (hardwareProp && hardwareProps.length > 0) {
        //    lines.push(" ");
        //    lines.push("var hardware = data.Get<MultiDeviceDataCloud>();");
        //    lines.push(" ");
        //    lines.push("foreach (var profile in hardware.Profiles)");
        //    lines.push("{");
        //    hardwareProps.map((selectedProperty) => {
        //        let property = selectedProperty.split('.').slice(-1)[0];
        //        lines.push(`    WriteProperty(profile.${property.replace(/-|\//g, '')});`);
        //    });
        //    lines.push("}");
        //}

        let code = `using FiftyOne.DeviceDetection;
using FiftyOne.Pipeline.Core.FlowElements;
using Microsoft.Extensions.DependencyInjection;
using Microsoft.Extensions.Logging;
using System;
using System.IO;
using System.Net.Http;
using System.Text;

namespace FiftyOne.DeviceDetection.Examples.Cloud.Configurator
{
    public class Program
    {
        public class Example
        {
            /// <summary>
            /// Holds a reference to the pipeline instances that is used to perform
            /// device detection.
            /// </summary>
            private IPipeline _pipeline;

            /// <summary>
            /// Constructor
            /// </summary>
            /// <param name="pipeline"></param>
            public Example(IPipeline pipeline)
            {
                _pipeline = pipeline;
            }

            /// <summary>
            /// Run the example
            /// </summary>
            /// <param name="output"></param>
            public void Run(TextWriter output)
            {
                // get a flow data from the singleton pipeline for each detection
                // it's important to free the flowdata when done
                using (var data = _pipeline.CreateFlowData())
                {
                    StringBuilder message = new StringBuilder();

                    // Add the evidence values to the flow data
                    data.AddEvidence(
                        "header.user-agent",
                        "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 " +
                        "(KHTML, like Gecko) Chrome/98.0.4758.102 Safari/537.36");
                    data.AddEvidence(
                        "header.sec-ch-ua-mobile",
                        "?0");
                    data.AddEvidence(
                        "header.sec-ch-ua",
                        "\\" Not A; Brand\\";v=\\"99\\", \\"Chromium\\";v=\\"98\\", " +
                        "\\"Google Chrome\\";v=\\"98\\"");
                    data.AddEvidence(
                        "header.sec-ch-ua-platform",
                        "\\"Windows\\"");
                    data.AddEvidence(
                        "header.sec-ch-ua-platform-version",
                        "\\"14.0.0\\"");

                    // Process the flow data.
                    data.Process();

                    // Get the results.
                    var device = data.Get<IDeviceData>();

${lines.join("\r\n")}
                }
            }

            /// <summary>
            /// This Run method is called by the example test to avoid the need to duplicate the
            /// service provider setup logic.
            /// </summary>
            /// <param name="options"></param>
            public static void Run(string resourceKey, TextWriter output)
            {
                // Initialize a service collection which will be used to create the services
                // required by the Pipeline and manage their lifetimes.
                using (var serviceProvider = new ServiceCollection()
                    // Add the configuration to the services collection
                    // Make sure we're logging to the console.
                    .AddLogging(l => l.AddConsole())
                    // Add an HttpClient instance. This is used for making requests to the
                    // cloud service.
                    .AddSingleton<HttpClient>()
                    .AddTransient<DeviceDetectionPipelineBuilder>()
                    // Add a factory to create the singleton IPipeline instance
                    .AddSingleton((x) =>
                        x.GetRequiredService<DeviceDetectionPipelineBuilder>()
                            .UseCloud(resourceKey)
                            .SetCloudRequestOrigin("www.51degrees-example.com")
                            .Build())
                    .AddTransient<Example>()
                    .BuildServiceProvider())
                {
                    serviceProvider.GetRequiredService<Example>().Run(output);
                }

            }
        }

        static void Main(string[] args)
        {
            string resourceKey = "${this.props.resource.key}";
            Example.Run(resourceKey, Console.Out);
        }
    }
}`

        return code;
    };

    render() {
        return (
            <div id={this.props.id} ref={this.props.refProp}>
                <h2 className="g-info__subheading">.NET</h2>
                {this.getPackages()}
                <article className="g-example-block">
                    <div className="c-code">
                        <header className="c-code__header">
                            <h2 className="c-code__heading">Example .NET code</h2>
                            <a href="#" className="c-copy-clipboard" onClick={this.copyCode}><img src="/images/icon-clipboard.svg" alt="Copy to clipboard" /></a>
                        </header>
                        <pre className="prettyprint">
                            <code className="lang-cs c-code__block">
                                <div dangerouslySetInnerHTML={{ __html: this.getCode().replace(/</g, '&lt;').replace(/>/g, '&gt;') }} />
                            </code>
                        </pre>
                    </div>
                    <Output examples={this.props.examples} selectedProperties={this.props.selectedProperties} />
                </article>
            </div>);
    }
}
