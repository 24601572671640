import React, { Component } from 'react';

import { flushSync } from 'react-dom';

import queryString from 'query-string';

import './css/main.css';

import { Configure } from './components/configure/Configure';
import { Details } from './components/details/Details';
import { Implement } from './components/implement/Implement';
import { Share } from './components/share/Share';
import { Header } from './components/header/Header';
import { Footer } from './components/footer/Footer';
import { Message } from './components/info/Message';
import { matomoTrackSection, matomoTrackCustomEvent } from './MatomoTracking';

const MAX_SECTION = 4;
const IMPLEMENT_SECTION = 3;
const MIN_SECTION = 1;

const newLicenseTypes = {
    FREE: 'free',
    NONE: 'none'
};

class App extends Component {
    displayName = App.name

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goto = this.goto.bind(this);
        this.updateTerms = this.updateTerms.bind(this);
        this.addToBasket = this.addToBasket.bind(this);
        this.addProperty = this.addProperty.bind(this);
        this.removeFromBasket = this.removeFromBasket.bind(this);
        this.readParams = this.readParams.bind(this);
        this.getProperties = this.getProperties.bind(this);
        this.register = this.register.bind(this);
        this.onSelectVendors = this.onSelectVendors.bind(this);

        // Create empty state to start with. Some elements might not be 
        // initialised from calls to external services.
        this.state = {
            section: MIN_SECTION,
            sectionUpdated: -1,
            selectedProperties: [],
            paidProperties: [],
            termsAgreed: false,
            receiveMarketingMessages: false,
            receiveProductUpdates: false,
            vendors: [],
            selectedVendors: [],
            components: [],
            categories: [],
            properties: [],
            examples: [],
            productDetails: [],
            resource: {},
            availableProperties: {},
            productKeys: [],
            clientEmail: '',
            clientDomains: [],
            clientDomainsString: '',
            selectAll: false,
            loading: true,
            error: '',
            referer: '',
            newLicenseType: newLicenseTypes.FREE,
            permittedLicenseTypes: [newLicenseTypes.FREE, newLicenseTypes.NONE],
            registered: false,
            showRegisterSuccess: false,
            showRegisterFailed: false,
            loadingLicense: 0,
        };

        // Common message to use if data load fails.
        const messLoadFail = 'Failed to load, try refreshing your browser ' +
            'or contact support@51degrees.com. ';

        // Start by getting all the data needed to populate the initial state.
        Promise.all([
            fetch('api/data/components'),
            fetch('api/data/categories'),
            fetch('api/data/properties'),
            fetch('api/data/vendors'),
            fetch('api/data/newresource'),
            fetch('api/data/accessibleproperties'),
            fetch('api/data/examples'),
            fetch('api/data/productinfo')
        ])
            .then(([res1, res2, res3, res4, res5, res6, res7, res8]) =>
                Promise.all([
                    res1.json(),
                    res2.json(),
                    res3.json(),
                    res4.json(),
                    res5.json(),
                    res6.json(),
                    res7.json(),
                    res8.json()]),
                (reject) => this.setState({
                    error: messLoadFail + reject
                }))
            .then(([componentData,
                categoryData,
                propertyData,
                vendorData,
                resourceData,
                availablePropertiesData,
                exampleData,
                productData]) => {

                var selectedVendorsData = [].concat.apply([], vendorData.map((vendor) => {
                    return vendor.map;
                }));

                // Set the state with the returned data. Use flush sync to 
                // ensure the state is up to date before resolving the promise.
                flushSync(() => this.setState({
                    components: componentData,
                    categories: categoryData,
                    properties: propertyData,
                    vendors: vendorData,
                    resource: resourceData,
                    availableProperties: availablePropertiesData,
                    examples: exampleData,
                    productDetails: productData,
                    loading: false,
                    selectedVendors: selectedVendorsData
                }));
            },
                (reject) => this.setState({
                    error: messLoadFail + reject
                }))
            .then(() => {
                this.readParams();
                this.getConfig();
            }).catch((ex) => this.setState({
                error: messLoadFail + ex
            }));
    }

    componentDidMount() {
        window.onbeforeunload = function (e) {
            e.preventDefault();
            e.returnValue = '';
        };
    }

    // Passed a configurator ID which is then fetched from global storage. If
    // a response is available then add the properties returned as a space or
    // comma separated list to the basket. Used to pre-configure a result from
    // the configurator.
    getProperties = (id) => {
        fetch('/props/' + id)
            .then(response => response.text())
            .then(data => data.split(/[, +]/).forEach(property => {
                this.addToBasket(property);
            }));
    }

    // Checks the location path for a configurator ID and populate associated 
    // properties if present and valid.
    getConfig = () => {
        let path = window.location.pathname;

        if (path.replace('/', '').length > 0) {
            this.getProperties(path);
        }
    }

    // Configures the state based on query string parameters.
    // Possible query string keys are:
    // selectedProperties : a comma separated list of properties as either the
    //   common name or the id.See this.state.properties for possible values.
    // implement : a configuration key used to return pre-set properties and
    //   then display the third step showing how to use the properties.
    // ref : a referer identifier used to monitor sharing of configuration.
    // vendors: the vendors to show in the UI to start with.
    readParams = () => {
        let params = queryString.parse(this.props.location.search);

        if (params.selectedProperties) {

            var props = params.selectedProperties.split(/[,+]/g);

            let tmp = props.map((s) => {
                let prop = undefined;
                prop = this.state.properties.find((property) =>
                    property.Name.toUpperCase() === s.toUpperCase() ||
                    property.Id.toUpperCase() == s.toUpperCase());
                if (prop) {
                    return prop.Id;
                }
                return undefined;
            });

            tmp.forEach((prop) => {
                this.addToBasket(prop);
            });
        }

        if (params.implement) {
            var resourceKey = params.implement;

            var res = {
                key: resourceKey,
                url: "https://cloud.51degrees.com/api/v4/" + resourceKey + ".js"
            };

            this.setState({
                section: IMPLEMENT_SECTION,
                resource: res,
                implementRecap: true
            });

            this.getProperties(resourceKey);
        }

        if (params.ref) {
            this.setState({
                referer: params.ref
            });
        }

        if (params.selectall && params.selectall === "true") {
            var tmpProps = this.state.properties.map((property) => {
                return property.Id;
            });

            tmpProps.forEach((prop) => {
                this.addToBasket(prop);
            });
        }

        if (params.vendors) {
            var vendors = params.vendors.split(/[\s,\+]+/);

            var vendorMaps = [].concat.apply([], this.state.vendors.map((vendor) => {
                return vendor.map;
            }));

            var selectedVendors = [];

            vendorMaps.forEach(vm => {
                if (vendors.indexOf(vm) !== -1) {
                    selectedVendors.push(vm);
                };
            });

            if (selectedVendors.length > 0) {
                this.setState({
                    selectedVendors: selectedVendors
                });
            }
        }
    }

    next = (e) => {
        e.preventDefault();
        var _section = this.state.section < MAX_SECTION ?
            this.state.section + 1 : MAX_SECTION;
        this.setState({ section: _section });
    }

    previous = (e) => {
        e.preventDefault();
        var _section = this.state.section > MIN_SECTION ?
            this.state.section - 1 : MIN_SECTION;
        this.setState({ section: _section });
    }

    goto = (_section) => {
        this.setState({ section: _section });
    }

    onSelectVendors = (item) => {
        if (this.state.selectedVendors.indexOf(item) === -1) {
            var joined = this.state.selectedVendors.concat(item);
            this.setState({ selectedVendors: joined });
        } else {
            var temp = this.state.selectedVendors;
            var index = temp.indexOf(item);
            if (index > -1) {
                temp.splice(index, 1);
            }
            this.setState({ selectedVendors: temp });
        }
    }

    updateTerms = () => {
        this.setState(
            { termsAgreed: !this.state.termsAgreed },
            () => this.setCanContinue());
        matomoTrackCustomEvent("TermsAgreed: ", !this.state.termsAgreed);
    }

    // Adds the property provided to the array of selected properties including
    // any related properties on which the provided property relies.
    addToBasket = (property) => {

        // Create a new array of selected properties and replace the existing
        // array.
        var joined = this.addProperty(property, this.state.selectedProperties);
        flushSync(() => this.setState({ selectedProperties: joined }));

        let availableProperties = this.state.availableProperties;
        let selectedProperties = []
        joined.forEach((property) => {
            var prop = this.state.properties.find((s) => s.Id === property);
            if (prop !== undefined) {
                selectedProperties.push(prop);
            }
        });

        let paidProperties = [];
        selectedProperties.forEach((property) => {
            var free = false;
            if (availableProperties.Products.hasOwnProperty(property.VendorId)) {
                free = availableProperties.Products[property.VendorId].Properties.some((prop) => {
                    return property.Id === property.VendorId + "." + prop.Name;
                });
            }
            if (!free)
                return paidProperties.push(property);
        });
        flushSync(() => this.setState({ paidProperties: paidProperties }));

        if (paidProperties.length > 0) {
            // Remove the free account as an option and default to none.
            this.addPermittedLicenseType(newLicenseTypes.NONE);
            this.removePermittedLicenseType(newLicenseTypes.FREE);
            this.setNewLicenseType(newLicenseTypes.NONE);
        } else if (this.state.newLicenseType !== newLicenseTypes.NONE) {
            this.addPermittedLicenseType(newLicenseTypes.FREE);
            this.setNewLicenseType(newLicenseTypes.FREE);
        }
    }

    // Returns any property metadata that includes the provided property in
    // the ItemProperties.
    getRelatedProperties = (property) => {
        var result = [];

        // Build an array of properties with the ItemProperties populated.
        var propsWithSubProps = this.state.properties.filter(p =>
            p.ItemProperties && p.ItemProperties.length > 0);

        // Find any properties where ItemProperties contains the property
        // passed to the method. Add these properties to the result.
        propsWithSubProps.forEach((p) => {
            p.ItemProperties.forEach((s) => {
                if (property.Name === s.Name) {
                    result.push(s);
                }
            });
        });

        return result;
    }

    // Creates a new array of properties which contains the property provided
    // if available in the array of all possible properties. Adds any related
    // properties that the property depends on. For example; if the property
    // requires a Accept-CH header values to provide an accurate response then
    // this related property would be added as well. If the property is not
    // available in the possible properties then the original properties array
    // is returned.
    //
    // property: to be added to the new array
    // properties: the existing array of properties
    // returns: a new array of which includes the properties array provided
    addProperty = (property, properties) => {

        // Find the property metadata from the configurator data model which is
        // associated with the property provided.
        var propertyMetaData = this.state.properties.find((element) => {
            return element.Id === property;
        });

        // If the property metadata exists and the properties array does not
        // already contain the property.
        if (propertyMetaData &&
            properties.indexOf(propertyMetaData.Id) === -1) {

            // Create a new array which consists of the current properties and 
            // the new one.
            var joined = properties.concat(propertyMetaData.Id);

            // If the property metadata has dependencies then add them to the 
            // joined array via a recursive call back to this method.
            if (propertyMetaData.Dependencies) {
                propertyMetaData.Dependencies.forEach((d) => {
                    if (joined.indexOf(d) === -1) {
                        joined = this.addProperty(d, joined);
                    }
                });
            }

            // Add any dependent properties to the new array of properties.
            var duplicateProperties = this.getRelatedProperties(
                propertyMetaData);
            duplicateProperties.forEach((p) => {
                joined = joined.concat(p.Id);
            });

            return joined;
        } else {
            return properties;
        }
    }

    removeFromBasket = (item) => {
        var tempSelectedProperties = this.state.selectedProperties;
        var index = tempSelectedProperties.indexOf(item);
        if (index > -1) {
            tempSelectedProperties.splice(index, 1);
        }
        var propertyMetaData = this.state.properties.find((element) => {
            return element.Id === item;
        });

        var duplicateProperties = this.getRelatedProperties(propertyMetaData);
        duplicateProperties.forEach((p) => {
            index = tempSelectedProperties.indexOf(p.Id);
            if (index > -1) {
                tempSelectedProperties.splice(index, 1);
            }
        });

        this.setState({ selectedProperties: tempSelectedProperties });

        let availableProperties = this.state.availableProperties;
        let selectedProperties = []
        tempSelectedProperties.forEach((property) => {
            var prop = this.state.properties.find((s) => s.Id === property);
            if (prop !== undefined) {
                selectedProperties.push(prop);
            }
        });

        let paidProperties = [];
        selectedProperties.forEach((property) => {
            var free = false;
            if (availableProperties.Products.hasOwnProperty(property.VendorId)) {
                free = availableProperties.Products[property.VendorId].Properties.some((prop) => {
                    return property.Id === property.VendorId + "." + prop.Name;
                });
            }
            if (!free)
                return paidProperties.push(property);
        });
        this.setState({ paidProperties: paidProperties });

        if (paidProperties.length > 0) {
            // Remove the free account as an option and default to none.
            this.addPermittedLicenseType(newLicenseTypes.NONE);
            this.removePermittedLicenseType(newLicenseTypes.FREE);
            this.setNewLicenseType(newLicenseTypes.NONE);
        } else if (this.state.newLicenseType !== newLicenseTypes.NONE) {
            this.addPermittedLicenseType(newLicenseTypes.FREE);
            this.setNewLicenseType(newLicenseTypes.FREE);
        }
    }

    updateAccessibleProperties = () => {
        var tmpProductKeys = this.state.productKeys;
        var params = [];

        if (this.state.registered) {
            params.push('resource=' + this.state.resource.key);
        }
        params.push('license=' + tmpProductKeys.map(pk => pk.value).join('+'));

        fetch('api/data/accessibleproperties?' + params.join('&'))
            .then(result => result.json())
            .then((data) => {
                var available = this.state.availableProperties;
                var nowavailable = Object.assign(available, data);

                this.setState({
                    availableProperties: nowavailable
                });
            });
    }

    // Indicate that the data model is being loaded.
    setLoading = (loading) => {
        this.setState({ loadingLicense: loading });
    }

    // Adds the licence key to the map of available licence keys.
    addKey = (key) => {
        if (this.state.productKeys.map(k => k.value).includes(key) === false) {
            var keys = this.state.productKeys.map(k => k.value).join('+');
            Promise.all([
                fetch('api/data/productinfo?licenseKeys=' + key),
                fetch('api/data/GetSubscriptionIDs?licenseKeys=' + keys),
                fetch('api/data/GetSubscriptionIDs?licenseKeys=' + key)
            ])
                .then(([res1, res2, res3]) => Promise.all([
                    res1.json(),
                    res2.json(),
                    res3.json()]))
                .then(([data, ids, keyId]) => {
                    var oldDetails = this.state.productDetails;
                    var newDetails = Object.assign(oldDetails, data);

                    var tmpProductKeys = this.state.productKeys;
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].valid === false) {
                            alert('This key is not valid: ' + data[i].message);
                            this.setLoading(this.state.loadingLicense - 1);
                            return;
                        }

                        var tmpTerms = '';
                        if (data[i].terms) {
                            tmpTerms = 'https://51degrees.com' + data[i].terms;
                        }

                        if (data[i].addon) {
                            if (ids.includes(keyId[0]) === false) {
                                alert('This add-on requires a valid subscription to the 51Degrees Cloud Service, please add a 51Degrees Subscription key first.');
                                this.setLoading(this.state.loadingLicense - 1);
                                return;
                            }
                        }

                        var keyData = {
                            value: key,
                            product: data[i].name,
                            requests: data[i].noOfRequestsPerMonth,
                            addon: data[i].addon,
                            terms: tmpTerms
                        };
                        tmpProductKeys.push(keyData);
                    }

                    this.setState({
                        productDetails: newDetails,
                        productKeys: tmpProductKeys,
                        termsAgreed: true
                    });
                    matomoTrackCustomEvent("Set Licence Key: '" + keyData.value + "'");

                    // TODO: Only set this if the licenses added so far 
                    // actually allow access to the requested properties
                    this.setNewLicenseType(newLicenseTypes.NONE);
                    this.addPermittedLicenseType(newLicenseTypes.NONE);
                    this.removePermittedLicenseType(newLicenseTypes.FREE);
                    // Update accessible properties
                    this.updateAccessibleProperties();
                });
        } else {
            alert('Key has already been added!');
        }
    }

    validateDetails = () => {
        var domains = this.state.clientDomains;
        let vendors = this.state.vendors;
        var paidProperties = this.state.paidProperties;
        var availableProperties = this.state.availableProperties;
        var email = this.state.clientEmail;
        var productKeys = this.state.productKeys;
        var newLicenseType = this.state.newLicenseType;

        // Validate domain names
        var validIpAddressRegex = new RegExp(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/);
        var validHostnameRegex = new RegExp(/^(([a-zA-Z0-9\*]|[a-zA-Z0-9\*][a-zA-Z0-9\-\*]*[a-zA-Z0-9\*])\.)*([A-Za-z0-9\*]|[A-Za-z0-9\*][A-Za-z0-9\-\*]*[A-Za-z0-9\*])$/);

        var invalidDomains = [];
        for (var i = 0; i < domains.length; i++) {
            if ((validIpAddressRegex.test(domains[i]) ||
                validHostnameRegex.test(domains[i])) === false) {
                invalidDomains.push(domains[i]);
            }
        }

        if (invalidDomains.length > 0) {
            alert('\'' + invalidDomains.join(', ') + '\' domain(s) not valid');
            return false;
        }

        // Validate product keys
        if (productKeys.length > 0 &&
            productKeys.filter(p => p.addon).length === productKeys.length) {
            alert('Only key(s) for Add-On(s) have been supplied. ' +
                'To use Add - Ons, a valid subscription to 51Degrees is ' +
                'required.');
            return false;
        }

        var requriedVendors = [];
        paidProperties.forEach((property) => {
            var vendor = vendors.find(v => v.map === property.VendorId);
            var propertyName = property.Id.split('.').slice(-1)[0];
            var available = availableProperties.Products[property.VendorId] &&
                availableProperties.Products[property.VendorId].Properties.find(p =>
                    p.Name === propertyName);
            if (requriedVendors.includes(vendor.name) === false &&
                available === undefined) {
                requriedVendors.push(vendor.name);
            }
        });

        if (newLicenseType !== newLicenseTypes.FREE && requriedVendors.length > 0) {
            return window.confirm('Keys for \'' + requriedVendors.join(', ') + '\' are still required, you won\'t be able to access some properties. Are you sure you want to continue?');
        }

        // Validate email
        var validEmailRegex = new RegExp("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");
        if ((validEmailRegex.test(String(email)) || email === '') === false) {
            alert('\'' + email + '\' is not a valid email.');
            return false;
        }

        return true;
    }

    // Update the allow domains in the state.
    updateDomains = (domains) => {
        this.setState({
            clientDomainsString: domains,
            clientDomains: domains.length === 0 ? [] :
                domains.replace(/ /g, '').split(',')
        });
    }

    // Set the email in the state.
    setEmail = (email) => {
        this.setState({ clientEmail: email });
    }

    // Register for a resource key for use with the cloud.
    register = () => {
        fetch('/api/configuration/register', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                resourceKey: this.state.resource.key,
                email: this.state.clientEmail,
                domains: this.state.clientDomains.join(' '),
                productKeys: this.state.productKeys.map(k => k.value).join(' '),
                properties: this.state.selectedProperties.join(' '),
                agreedToTerms: this.state.termsAgreed,
                receiveMarketingEmails: this.state.receiveMarketingMessages,
                receiveProductUpdates: this.state.receiveProductUpdates,
                registrationEmailSent: this.state.registered
            })
        }).then((response) => {
            if (response.ok) {
                this.setState({ showRegisterFailed: false });
            } else {
                this.setState({ showRegisterFailed: true });
                alert("Registration failed, please go and check your email " +
                    "address is entered correctly.")
                throw new Error('Network response was not ok');
            }
            return response.json();
        }).then(data => {
            if (data) {
                this.setState({ registered: data });
                this.setState({ showRegisterSuccess: true });
            } else {
                this.setState({ showRegisterSuccess: false });
            }
        }).catch(error => {
            this.setState({ showRegisterFailed: true });
        });
    }

    // Update the state with to indicate marketing emails are allowed.
    consentToMarketing = (value) => {
        this.setState({ receiveMarketingMessages: value });
        matomoTrackCustomEvent("receiveMarketingMesseges: ",
            value);
    }

    // Update the state with a flag to indicate product updates are allowed.
    consentToProductUpdates = (value) => {
        this.setState({ receiveProductUpdates: value });
        matomoTrackCustomEvent("receiveProductUpdates: ",
            value);
    }

    // Update the state with the licence type indicator. See newLicenseTypes.
    setNewLicenseType = (licenseType) => {
        this.setState(
            { newLicenseType: licenseType },
            () => this.setCanContinue());
    }

    // Add the license type to the permitted types. See newLicenseTypes.
    addPermittedLicenseType = (licenseType) => {
        let types = this.state.permittedLicenseTypes;
        if (types.indexOf(licenseType) < 0) {
            types.push(licenseType);
            this.setState({ permittedLicenseTypes: types });
        }
    }

    // Remove the permitted license type. See newLicenseTypes.
    removePermittedLicenseType = (licenseType) => {
        let types = this.state.permittedLicenseTypes;
        if (types.indexOf(licenseType) >= 0) {
            types.splice(types.indexOf(licenseType), 1);
            this.setState({ permittedLicenseTypes: types });
        }
    }

    // Enable the user to move to the next step.
    setCanContinue = () => {
        let license = this.state.newLicenseType;
        let termsAgreed = this.state.termsAgreed;
        let canContinue =
            this.state.permittedLicenseTypes.includes(license) &&
            (license === newLicenseTypes.FREE ||
                license === newLicenseTypes.NONE) &&
            termsAgreed === true;
        this.setState({ canContinue: canContinue });
    }

    // Displays the current section if loading is complete.
    displaySection() {
        let vendors = this.state.vendors;
        let selectedVendors = this.state.selectedVendors;
        let properties = this.state.properties;
        let categories = this.state.categories;
        let components = this.state.components;
        let examples = this.state.examples;

        if (this.state.loading) {
            if (this.state.error !== '') {
                return (
                    <Message message={this.state.error} />
                );
            } else {
                return (
                    <Message message={'Loading...'} />
                );
            }
        }
        else {
            switch (this.state.section) {
                case 1:
                    matomoTrackSection(
                        this.state.section,
                        this.state.sectionUpdated,
                        "Configure",
                        this.setState.bind(this));
                    return (
                        <Configure
                            selectedProperties={this.state.selectedProperties}
                            availableProperties={this.state.availableProperties}
                            vendors={vendors}
                            selectedVendors={selectedVendors}
                            onSelectVendors={this.onSelectVendors}
                            properties={properties}
                            categories={categories}
                            components={components}
                            examples={examples}
                            addToBasket={this.addToBasket}
                            removeFromBasket={this.removeFromBasket}
                        />);
                case 2:
                    matomoTrackSection(
                        this.state.section,
                        this.state.sectionUpdated,
                        "Details",
                        this.setState.bind(this));
                    return (
                        <Details
                            selectedProperties={this.state.selectedProperties}
                            paidProperties={this.state.paidProperties}
                            availableProperties={this.state.availableProperties}
                            vendors={vendors}
                            properties={properties}
                            components={components}
                            removeFromBasket={this.removeFromBasket}
                            domains={this.state.clientDomainsString}
                            updateDomains={this.updateDomains}
                            addKey={this.addKey}
                            clientEmail={this.state.clientEmail}
                            receiveMarketingMessages={this.state.receiveMarketingMessages}
                            receiveProductUpdates={this.state.receiveProductUpdates}
                            consentToMarketing={this.consentToMarketing}
                            consentToProductUpdates={this.consentToProductUpdates}
                            setEmail={this.setEmail}
                            register={this.register}
                            registered={this.state.registered}
                            productDetails={this.state.productDetails}
                            productKeys={this.state.productKeys}
                            permittedLicenseTypes={this.state.permittedLicenseTypes}
                            newLicenseType={this.state.newLicenseType}
                            removePermittedLicenseType={this.removePermittedLicenseType}
                            addPermittedLicenseType={this.addPermittedLicenseType}
                            setNewLicenseType={this.setNewLicenseType}
                            newLicenseTypes={newLicenseTypes}
                            showRegisterSuccess={this.state.showRegisterSuccess}
                            showRegisterFailed={this.state.showRegisterFailed}
                            loadingLicense={this.state.loadingLicense}
                            setLoading={this.setLoading}
                        />);
                case 3:
                    matomoTrackSection(
                        this.state.section,
                        this.state.sectionUpdated,
                        "Implement",
                        this.setState.bind(this));
                    return (
                        <Implement
                            selectedProperties={this.state.selectedProperties}
                            properties={properties}
                            examples={examples}
                            resource={this.state.resource}
                            referer={this.state.referer}
                        />);
                case 4:
                    matomoTrackSection(
                        this.state.section,
                        this.state.sectionUpdated,
                        "Share",
                        this.setState.bind(this));
                    return (
                        <Share
                            selectedProperties={this.state.selectedProperties}
                            clientEmail={this.state.clientEmail}
                            receiveMarketingMessages={this.state.receiveMarketingMessages}
                            receiveProductUpdates={this.state.receiveProductUpdates}
                            consentToMarketing={this.consentToMarketing}
                            consentToProductUpdates={this.consentToProductUpdates}
                            setEmail={this.setEmail}
                            register={this.register}
                            registered={this.state.registered}
                            validateDetails={this.validateDetails}
                            showRegisterSuccess={this.state.showRegisterSuccess}
                            showRegisterFailed={this.state.showRegisterFailed}
                        />);
                default:
                    matomoTrackSection(
                        this.state.section,
                        this.state.sectionUpdated,
                        "Error",
                        this.setState.bind(this));
                    return (
                        <main className="g-main">
                            <div className="g-panel g-panel--100">
                                <article className="g-info">
                                    <div>Error...</div>
                                </article>
                            </div>
                        </main>
                    );
            }
        }
    }

    render() {
        var classes = "l-index";

        switch (this.state.section) {
            case 1:
                classes += " p-configure";
                break;
            case 2:
                classes += " p-details";
                break;
            case 3:
                classes += " p-implement";
                break;
            case 4:
                classes += " p-share";
                break;
            default:
                break;
        }

        return (
            <div className={classes}>
                <div className="l-index__content">
                    <Header
                        termsAgreed={this.state.termsAgreed}
                        section={this.state.section}
                        goto={this.goto}
                    />
                    {this.displaySection()}
                    <Footer
                        removeSelectedProperty={this.removeFromBasket}
                        properties={this.state.properties}
                        selectedProperties={this.state.selectedProperties}
                        availableProperties={this.state.availableProperties}
                        vendors={this.state.vendors}
                        section={this.state.section}
                        termsAgreed={this.state.termsAgreed}
                        updateTerms={this.updateTerms}
                        next={this.next}
                        previous={this.previous}
                        canContinue={this.state.canContinue}
                        permittedLicenseTypes={this.state.permittedLicenseTypes}
                        newLicenseTypes={newLicenseTypes}
                        validateDetails={this.validateDetails}
                        matomoTrackSection={this.matomoTrackSection}
                    />
                </div>
            </div>
        );
    }
}

// Work around for deprecation of withRouter - https://stackoverflow.com/questions/71837081/export-withrouter-imported-as-withrouter-was-not-found-in-react-router-do

import { useLocation, useNavigate, useParams } from 'react-router-dom';

const withRouter = Component => props => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (
        <Component
            {...props}
            location={location}
            navigate={navigate}
            params={params}
        />
    );
};

const AppWithRouter = withRouter(App);

function AppWithMatomo() {
    React.useEffect(() => {
        var _mtm = window._mtm = window._mtm || [];
        _mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
        var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
        g.async = true; g.src = 'https://cdn.matomo.cloud/51degrees.matomo.cloud/container_irj7x47W.js'; s.parentNode.insertBefore(g, s);
    }, [])

    return <AppWithRouter />;
}

export default AppWithMatomo;