import React, { Component } from 'react';
import { VendorListItem } from './VendorListItem';

export class VendorList extends Component {
    displayName = VendorList.name

    constructor(props) {
        super(props);
        this._open = this._open.bind(this);
        this._handleChange = this._handleChange.bind(this);

        this.state = {
            opened: false
        };
    }

    _open = () => {
        this.setState({ opened: !this.state.opened });
    }

    _handleChange = (item) => {
        const { onChange } = this.props;
        onChange(item);
    }

    render() {
        let classes = "c-filter is-openable";

        if (this.state.opened)
            classes += " is-opened";

        let selectedVendors = this.props.selectedVendors;

        return (
            <div className={classes}>
                <a href="#" className="c-filter__toggle" onClick={(e) => { e.preventDefault(); this._open(); }}>Filter<span> properties by vendor</span></a>
                <div className="c-filter__content">
                    <ul className="c-filter__list">
                        {this.props.vendors.map((vendor, i) => {
                            let _checked = selectedVendors.indexOf(vendor.map) > -1;

                            return (
                                <VendorListItem key={i} vendor={vendor} checked={_checked} onChange={this._handleChange} />
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}