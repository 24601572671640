import React, { Component } from 'react';

export class ProgressItem extends Component {

    onClick = (e) => {
        e.preventDefault();
        if (this.props.enabled) {
            this.props.onClick(this.props.value);
        }
    }

    render() {
        var classes = "c-progress__link";

        if (this.props.active === this.props.value) {
            classes += " c-progress__link--active";
        }

        return (
            <li className="c-progress__item">
                <a href="#" className={classes} value={this.props.value} onClick={this.onClick}><span className="c-progress__count">{this.props.value}</span> <span className="c-progress__text" />{this.props.name}</a>
            </li>
        );
    }
}