import React, { Component } from 'react';
import { PropertyInfo } from './PropertyInfo';

export class Property extends Component {

    displayName = this.props.property.Name;

    constructor(props) {
        super(props);
        this._handleCheck = this._handleCheck.bind(this);
    }

    _handleClick = (e) => {
        e.preventDefault();
        const { property, onClick } = this.props;
        onClick(property.Id);
    }

    _handleCheck() {
        const { property, onCheck } = this.props;
        onCheck(property.Id);
    }

    // Returns true if the property is billable, otherwise false.
    getIsBillable(property) {
        const availableProperties = this.props.availableProperties;
        var isBillable = true;
        if (availableProperties.Products[property.VendorId]) {
            isBillable = !availableProperties.Products[property.VendorId].Properties.find((prop) => {
                return property.VendorId + "." + prop.Name === property.Id;
            });
        }   
        return isBillable;
    }

    back = () => {
        const { onClick } = this.props;
        onClick('');
    }

    render() {
        const property = this.props.property;
        const isBillable = this.getIsBillable(property);
        const vendor = this.props.vendors.find((vendor) => {
            return vendor.map === property.VendorId;
        });
        const checked = this.props.checked;
        return (
            <li className={`g-panel__item ${this.props.active ? "is-active" : ""}`}>
                <div className="c-option">
                    <span className="c-select  c-select--large">
                        <input type="checkbox" className="c-select__input is-selectable" id={property.Id} data-property={property.Id} onChange={this._handleCheck} checked={checked}/>
                        <label className="c-select__label" htmlFor={property.Id}>
                            <span className="c-select__text">{this.displayName}</span>
                        </label>
                    </span>
                    <a href="#" className={`c-option__link is-revealable ${this.props.active ? "is-active" : ""}`} onClick={this._handleClick}>
                        {this.displayName}
                        <span className="c-option__additional">
                            <span className="c-option__vendor"><img src={vendor.iconUrl} alt={vendor.name} width="13" height="13" /></span>
                            {isBillable ? <span className="c-option__billable"><span>Billable</span></span> : null}
                            <span className="c-option__info"><span>Info</span></span>
                        </span>
                    </a>
                </div>
                <PropertyInfo
                    property={property}
                    category={this.props.category}
                    isBillable={isBillable}
                    active={this.props.active}
                    back={this.back}
                    checked={checked}
                    onCheck={this._handleCheck}
                    vendor={vendor}
                    examples={this.props.examples}
                />
            </li>
        );
    }
}