import React, { Component } from 'react';

import { ProgressItem } from './ProgressItem';

let logo = "https://51degrees.com/img/logo.png";

export class Header extends Component {
    displayName = Header.name;

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
            }

    onClick = (_section) => {
        const { goto } = this.props;
        goto(_section);
    }

    render() {
        return (
            <div className="g-header">
                <h1 className="c-brand">
                    <a href="#" className="c-brand__link" onClick={(e) => { e.preventDefault(); this.onClick(1); }}>
                        <img src={logo} alt="51 Degrees Cloud Configurator" className="c-brand__image" />
                    </a>
                    <span className="c-tagline">Customisable device detection and more, in three easy steps</span>
                </h1>
                <ul className="c-progress">
                    <ProgressItem value={1} onClick={this.onClick} enabled active={this.props.section} name={"Configure"}/>
                    <ProgressItem value={2} onClick={this.onClick} enabled={this.props.termsAgreed} active={this.props.section} name={"Details"}/>
                    <ProgressItem value={3} onClick={this.onClick} enabled={this.props.termsAgreed} active={this.props.section} name={"Implement"}/>
                </ul>
            </div>    
        );
    }
}