import React, { Component } from 'react';
import { ClientSideImplementation } from './ClientSideImplementation';

export class ClientSide extends Component {
    displayName = ClientSide.name;

    constructor(props) {
        super(props);
    }

    name = 'ClientSide'

    runCodePrettify = () => {
        const { runCodePrettify } = this.props;
        runCodePrettify();
    }

    handleClick = (e) => {
        e.preventDefault();
        const { handleClick } = this.props;
        handleClick(this.name);
    }

    render() {
        let _active = this.props.active === this.name;

        let classes = "c-category__link is-revealable";

        if (_active)
            classes += " is-active";

        return (
            <li key="clientside" className="g-panel__item">
                <a href="#" className={classes} onClick={this.handleClick}>Client side</a>
                <ClientSideImplementation
                    active={_active}
                    properties={this.props.properties}
                    selectedProperties={this.props.selectedProperties}
                    hiddenProperties={this.props.hiddenProperties}
                    examples={this.props.examples}
                    resource={this.props.resource}
                    handleClick={this.props.handleClick}
                    runCodePrettify={this.runCodePrettify}
                />
            </li>
        );
    }
}

