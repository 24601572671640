import React, { Component } from 'react';
import { Output } from './../Output';

export class Node extends Component {
    displayName = Node.name;

    constructor(props) {
        super(props);

        this.selectedProperties = this.props.selectedProperties.filter(p => p.startsWith("ip.") === false &&
            p.startsWith("location_consolidated.") === false &&
            p.startsWith("country_list.") === false &&
            p.startsWith("local_countryname.") === false &&
            p.startsWith("neighbouring_countries.") === false);
    }

    copyCode = (e) => {
        e.preventDefault();
        this.copy(this.getCode());
    };

    copy = (data) => {
        var dummy = document.createElement('textarea');
        document.body.appendChild(dummy);
        dummy.value = data;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert('Copied!');
    };

    getPackages = () => {
        let dataKeys = [];

        this.props.selectedProperties.forEach(prop => {
            var dataKey = prop.substring(0, prop.lastIndexOf('.'));
            if (dataKeys.indexOf(dataKey) === -1) {
                dataKeys.push(dataKey);
            }
        });

        let elements = [];
        let addedPackages = [];

        dataKeys.forEach(dataKey => {
            switch (dataKey) {
                case 'device':
                case 'hardware.Profile':
                    if (addedPackages.indexOf("device") === -1) {
                        addedPackages.push("device");
                        elements.push(this.props.newPackage(this.copy, 'NPM - Device Detection', 'npm i fiftyone.devicedetection.cloud', 'fod-dd'));
                    }
                    break;
                case 'location':
                case 'location_digitalelement':
                    if (addedPackages.indexOf("location") === -1) {
                        addedPackages.push("location");
                        elements.push(this.props.newPackage(this.copy, 'NPM - Geo Location', 'npm i fiftyone.geolocation', 'fod-geo'));
                    }
                    break;
                default:
                    break;
            }
        });

        return elements;
    }

    addReverseGeoLocationEvidence = (evidenceList) => {
        var lat = "flowData.evidence.add('query.51D_Pos_latitude', '51.458048');";
        var lon = "flowData.evidence.add('query.51D_Pos_longitude', '-0.9822207999999999');";

        if (evidenceList.indexOf(lat) === -1) {
            evidenceList.push(lat);
        }

        if (evidenceList.indexOf(lon) === -1) {
            evidenceList.push(lon);
        }
    }

    getCode = () => {
        let addToArray = this.props.addToArray;
        let dataKeys = this.props.dataKeys;

        let imports = [];
        let engines = [];
        let addEngines = [];
        let evidence = [];

        dataKeys.forEach(dataKey => {
            switch (dataKey) {
                case 'device':
                    addToArray(imports, "const FiftyOneDegreesDeviceDetection = require('fiftyone.devicedetection.cloud');");
                    engines.push(`const deviceDetectionCloudEngine =
  new FiftyOneDegreesDeviceDetection.DeviceDetectionCloud();`);
                    addEngines.push('.add(deviceDetectionCloudEngine)');
                    evidence.push(`flowData.evidence.add('header.user-agent', 
                'Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G960U) ' +
                'AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/10.1 ' +
                'Chrome/71.0.3578.99 Mobile Safari/537.36');`);
                    break;
                case 'hardware.Profiles':
                    addToArray(imports, "const FiftyOneDegreesDeviceDetection = require('fiftyone.devicedetection.cloud');");
                    engines.push(`const hardwareProfileEngine =
  new FiftyOneDegreesDeviceDetection.HardwareProfileCloudEngine()`);
                    addEngines.push('.add(hardwareProfileEngine)');
                    evidence.push("flowData.evidence.add('query.tac', '35925406');");
                    evidence.push("//data.addEvidence('query.nativemodel', 'iPhone11,8');");
                    break;
                case 'location':
                    addToArray(imports, "const FiftyOneDegreesGeoLocation = require('fiftyone.geolocation');");
                    engines.push(`const fodLocationCloudEngine = 
  new FiftyOneDegreesGeoLocation.GeoLocationCloud({
    locationProvider: 'fiftyonedegrees'
  });`);
                    addEngines.push('.add(fodLocationCloudEngine)');
                    this.addReverseGeoLocationEvidence(evidence);
                    break;
                case 'local_countryname':
                    break;
                case 'location_consolidated':
                    break;
                case 'neighbouring_countries':
                    break;
                default:
                    break;
            }
        });

        let lines = this.selectedProperties
            .filter(prop =>
                prop.startsWith("hardware.Profiles") === false &&
                prop.startsWith("device") &&
                this.props.hiddenProperties.indexOf(prop.toLowerCase()) === -1)
            .map((selectedProperty, i) => {
                var prop = selectedProperty.split('.');

                // access properties with special characters using property as 
                // key
                var propertyName = prop[1].toLowerCase();
                if (propertyName.indexOf("-") !== -1 || propertyName.indexOf("/") !== -1) {
                    propertyName = `['${propertyName}']`;
                } else {
                    propertyName = "." + propertyName;
                }
                return `  output.write(\`device.${prop[1]}: \${device${propertyName}.value}\\n\`);`
            });
        if (lines.length === 0) {
            // No device detection properties, so just add IsMobile.
            lines[0] = `  output.write(\`IsMobile: \${device.ismobile.value}\n\`);`
        }
      //  let hardwareProp = this.props.selectedProperties.indexOf("hardware.Profiles") !== -1;
      //  let hardwareProps = this.props.selectedProperties.filter(prop => prop.startsWith("hardware.Profiles."));
      //  if (hardwareProp && hardwareProps.length > 0) {
      //      lines.push(" ");
      //      lines.push("// Check hardware profile properties.");
      //      lines.push(`console.log("hardware.Profiles...");`)
      //      lines.push("flowData.hardware.profiles.forEach(");
      //      lines.push("  function(profile, index, listObj) {");
      //      hardwareProps.map((selectedProperty) => {
      //          let propertyName = selectedProperty.split('.').slice(-1)[0].toLowerCase();
      //          if (propertyName.indexOf("-") !== -1 || propertyName.indexOf("/") !== -1) {
      //              propertyName = `['${propertyName}']`;
      //          } else {
      //              propertyName = "." + propertyName;
      //          }

      //          lines.push(`    console.log("${selectedProperty}: ");
      //checkProperty(profile${propertyName});`);
      //      });
      //      lines.push("  }");
      //      lines.push(");");

      //  }

        let code = `const path = require('path');
const DeviceDetectionCloudPipelineBuilder =
  require('fiftyone.devicedetection.cloud').DeviceDetectionCloudPipelineBuilder;
const run = async function (resourceKey, output) {
  // The pipeline should be managed as a singleton. Creating a pipeline instance for every request
  // will cause extreme resource problems.
  const pipeline = new DeviceDetectionCloudPipelineBuilder({
    resourceKey: resourceKey,
    // inhibit sharing usage for this test, usually this should be set 'true'.
    shareUsage: false
  }).build();
  // To monitor the pipeline we can put in listeners for various log events.
  // Valid types are info, debug, warn, error
  pipeline.on('error', console.error);
  // Get a flow data from the singleton pipeline for each detection
  const data = pipeline.createFlowData();
  // Add the evidence values to the flow data
  data.evidence.add(
    'header.user-agent',
    'Mozilla/5.0 (Windows NT 10.0; Win64; x64) ' +
    'AppleWebKit/537.36 (KHTML, like Gecko) ' +
    'Chrome/98.0.4758.102 Safari/537.36');
  data.evidence.add(
    'header.sec-ch-ua-mobile',
    '?0');
  data.evidence.add(
    'header.sec-ch-ua',
    '" Not A; Brand";v="99", "Chromium";v="98", ' +
    '"Google Chrome";v="98"');
  data.evidence.add(
    'header.sec-ch-ua-platform',
    '"Windows"');
  data.evidence.add(
    'header.sec-ch-ua-platform-version',
    '"14.0.0"');
  // Process the flow data.
  await data.process();
  // Get the results.
  const device = data.device;
${lines.join("\r\n")}
};

const args = process.argv.slice(2);
const resourceKey = "${this.props.resource.key}";
run(resourceKey, process.stdout);

module.exports = {
  run: run
};`;

        return code;
    };

    render() {
        return (
            <div id={this.props.id} ref={this.props.refProp}>
                <h2 className="g-info__subheading">Node</h2>
                {this.getPackages()}
                <article className="g-example-block">
                    <div className="c-code">
                        <header className="c-code__header">
                            <h2 className="c-code__heading">Example Node.js code</h2>
                            <a href="#" className="c-copy-clipboard" onClick={this.copyCode}><img src="/images/icon-clipboard.svg" alt="Copy to clipboard" /></a>
                        </header>
                        <pre className="prettyprint">
                            <code className="lang-cs c-code__block">
                                <div dangerouslySetInnerHTML={{ __html: this.getCode().replace(/</g, '&lt;').replace(/>/g, '&gt;') }} />
                            </code>
                        </pre>
                    </div>
                    <Output examples={this.props.examples} selectedProperties={this.selectedProperties} />
                </article>
            </div>);
    }
}
