import React, { Component } from 'react';

export class DetailsNav extends Component {
    displayName = DetailsNav.name;

    constructor(props) {
        super(props);
    }

    updateTerms = () => {
        const { updateTerms } = this.props;
        updateTerms();
    }

    next = (e) => {
        const { next, validateDetails } = this.props;
        if (this.props.termsAgreed && this.props.canContinue && validateDetails())
            next(e);
    }

    render() {
        let permittedLicenseTypes = this.props.permittedLicenseTypes;
        let newLicenseTypes = this.props.newLicenseTypes;

        let free = true;
        let spanClasses = "c-select";
        if (permittedLicenseTypes.includes(newLicenseTypes.NONE)) {
            free = false;
            spanClasses += " c-card--disabled";
        }

        var nextButtonClasses = "b-btn b-btn--large";

        if (!(this.props.termsAgreed && this.props.canContinue))
            nextButtonClasses += " b-btn--disabled";

        return (
            <div className="g-footer__navigation">
                <div className="g-footer__previous">
                    <a href="#" className="b-btn b-btn--large b-btn--secondary" onClick={this.props.previous}><img src="/images/icon-arrow-left-black.svg" className="b-btn__icon" alt="Previous" /><em>Previous step:</em>&nbsp;<strong>Configure</strong></a>
                </div>
                <div className="g-footer__next">
                    <span className={spanClasses}>
                        <input type="checkbox" className="c-select__input is-selectable" id="customCheckterms" data-property="" checked={this.props.termsAgreed} onChange={this.updateTerms.bind(this)} disabled={!free}/>
                        <label className="c-select__label" htmlFor="customCheckterms">{free ? <span className="c-select__text">Review and accept our <a href="https://51degrees.com/terms/free/" target="_blank" className="b-link">Terms and Conditions</a></span> : <span className="c-select__text">The terms of your subscription apply to the use of this service</span>}</label>
                    </span>
                    <a href="#" className={nextButtonClasses} onClick={this.next}><em>Next step:</em>&nbsp;<strong>Implement</strong><img src="/images/icon-arrow-right-white.svg" className="b-btn__icon b-btn__icon--after" alt="Next" /></a>
                </div>
            </div>
        );
    }
}