import React, { Component } from 'react';

export class Message extends Component {
    displayName = Message.name;

    render() {
        return (
            <div>
                <p>{this.props.message}</p>
            </div>
        );
    }
}
