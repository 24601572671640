import React, { Component } from 'react';
import { Output } from './../Output';

export class Java extends Component {
    displayName = Java.name;

    constructor(props) {
        super(props);

        this.selectedProperties = this.props.selectedProperties.filter(p => p.startsWith("ip.") === false &&
            p.startsWith("location_consolidated.") === false &&
            p.startsWith("country_list.") === false &&
            p.startsWith("local_countryname.") === false &&
            p.startsWith("neighbouring_countries.") === false);
    }

    copyCode = (e) => {
        e.preventDefault();
        this.copy(this.getCode());
    };

    copy = (data) => {
        var dummy = document.createElement('textarea');
        document.body.appendChild(dummy);
        dummy.value = data;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert('Copied!');
    };

    getPackages = () => {
        let dataKeys = this.props.dataKeys;
        let elements = [];

        dataKeys.forEach(dataKey => {
            switch (dataKey) {
                case 'device':
                    elements.push(this.props.newPackage(this.copy, 'Pom - Device Detection', `<dependency>
  <groupId>com.51degrees</groupId>
  <artifactId>device-detection</artifactId>
  <version>[4.4.46,)</version>
</dependency>`, 'fod-dd'));
                    break;
                case 'location':
                    elements.push(this.props.newPackage(this.copy, 'Pom - Geo Location', `<dependency>
  <groupId>com.51degrees</groupId>
  <artifactId>geo-location</artifactId>
  <version>[4.4.8,)</version>
</dependency>`, 'fod-geo'));
                    break;
                default:
                    break;
            }
        });

        return elements;
    }

    addReverseGeoLocationEvidence = (evidenceList) => {
        var lat = 'data.addEvidence("query.51D_Pos_latitude", "51.458048");';
        var lon = 'data.addEvidence("query.51D_Pos_longitude", "-0.9822207999999999");';

        if (evidenceList.indexOf(lat) === -1) {
            evidenceList.push(lat);
        }

        if (evidenceList.indexOf(lon) === -1) {
            evidenceList.push(lon);
        }
    }

    getCode = () => {
        let addToArray = this.props.addToArray;
        let dataKeys = this.props.dataKeys;

        let imports = [];
        let engines = [];
        let addEngines = [];
        let evidence = [];
        let data = [];

        dataKeys.forEach(dataKey => {
            switch (dataKey) {
                case 'device':
                    addToArray(imports, 'import fiftyone.devicedetection.cloud.flowelements.DeviceDetectionCloudEngine;');
                    addToArray(imports, 'import fiftyone.devicedetection.cloud.flowelements.DeviceDetectionCloudEngineBuilder;');
                    addToArray(imports, 'import fiftyone.devicedetection.shared.DeviceData;');
                    engines.push(`DeviceDetectionCloudEngine deviceDetectionCloudEngine =
                new DeviceDetectionCloudEngineBuilder(loggerFactory)
                .build();`);
                    addEngines.push('.addFlowElement(deviceDetectionCloudEngine)');
                    evidence.push(`data.addEvidence("header.user-agent", 
                "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G960U) " +
                "AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/10.1 " +
                "Chrome/71.0.3578.99 Mobile Safari/537.36");`);
                    data.push('DeviceData device = data.getFromElement(deviceDetectionCloudEngine);');
                    break;
                case 'hardware.Profiles':
                    addToArray(imports, 'import fiftyone.devicedetection.cloud.flowelements.HardwareProfileCloudEngine;');
                    addToArray(imports, 'import fiftyone.devicedetection.cloud.flowelements.HardwareProfileCloudEngineBuilder;');
                    addToArray(imports, 'import fiftyone.devicedetection.shared.DeviceData;');
                    addToArray(imports, 'import fiftyone.devicedetection.cloud.data.MultiDeviceDataCloud;');
                    engines.push(`HardwareProfileCloudEngine hardwareProfileEngine =
                new HardwareProfileCloudEngineBuilder(loggerFactory)
                .build();`);
                    addEngines.push('.addFlowElement(hardwareProfileEngine)');
                    evidence.push('data.addEvidence("query.tac", "35925406");');
                    evidence.push('//data.addEvidence("query.nativemodel", "iPhone11,8");');
                    break;
                case 'location':
                    addToArray(imports, 'import fiftyone.geolocation.core.data.GeoData;');
                    addToArray(imports, 'import fiftyone.geolocation.flowelements.GeoLocationCloudEngine;');
                    addToArray(imports, 'import fiftyone.geolocation.flowelements.GeoLocationCloudEngineBuilder;');
                    engines.push(`GeoLocationCloudEngine fodLocationCloudEngine = 
                new GeoLocationCloudEngineBuilder(loggerFactory)
                .setGeoLocationProvider("FiftyOneDegrees")
                .build();`);
                    addEngines.push('.addFlowElement(fodLocationCloudEngine)');
                    this.addReverseGeoLocationEvidence(evidence);
                    data.push('GeoData location = data.getFromElement(fodLocationCloudEngine);');
                    break;
                case 'local_countryname':
                    break;
                case 'location_consolidated':
                    break;
                case 'neighbouring_countries':
                    break;
                default:
                    break;
            }
        });

        let lines = this.selectedProperties
            .filter(prop =>
                prop.startsWith("hardware.Profiles") === false &&
                prop.startsWith("device") &&
                this.props.hiddenProperties.indexOf(prop.toLowerCase()) === -1)
            .map((selectedProperty, i) => {
                var selectedPropertyParts = selectedProperty.split('.');
                return `                System.out.println("${selectedProperty}: " + device.get${selectedPropertyParts[1].replace(/-|\//g, '')}().getValue());`
            });
        if (lines.length === 0) {
            // No device detection properties, so just add IsMobile.
            lines[0] = `                System.out.println("device.IsMobile: " + device.getIsMobile().getValue());`
        }

        //let hardwareProp = this.props.selectedProperties.indexOf("hardware.Profiles") !== -1;
        //let hardwareProps = this.props.selectedProperties.filter(prop => prop.startsWith("hardware.Profiles."));
        //if (hardwareProp && hardwareProps.length > 0) {
        //    lines.push(" ");
        //    lines.push("MultiDeviceDataCloud hardware = data.get(MultiDeviceDataCloud.class);");
        //    lines.push(" ");
        //    lines.push('System.out.println();');
        //    lines.push('System.out.println("hardware.Profiles...");');
        //    lines.push("for (DeviceData profile : hardware.getProfiles()){");
        //    hardwareProps.map((selectedProperty) => {
        //        let property = selectedProperty.split('.').slice(-1)[0];
        //        lines.push(`    PrintProperty("\t${property}: ", profile.get${property.replace(/-|\//g, '')}());`);
        //    });
        //    lines.push('    System.out.println();');
        //    lines.push("}");
        //}

        let code = `package fiftyone.devicedetection.examples.console;
import fiftyone.devicedetection.DeviceDetectionPipelineBuilder;
import fiftyone.devicedetection.shared.DeviceData;
import fiftyone.pipeline.core.data.FlowData;
import fiftyone.pipeline.core.flowelements.Pipeline;

public class MinimalExample {
    public static void main(String[] args) throws Exception {
        String resource = "${this.props.resource.key}";

        run(resource);
    }
    public static void run(String resource) throws Exception {
        // create a minimal pipeline to access the cloud engine
        // you only need one pipeline for multiple requests
        // use try-with-resources to free the pipeline when done
        try (Pipeline pipeline = new DeviceDetectionPipelineBuilder()
                .useCloud(resource)
                .build()) {
            /* get a flow data from the singleton pipeline for each detection */
            // it's important to free the flowdata when done
            try (FlowData data = pipeline.createFlowData()) {
                // add user-agent and client hint headers (if any) from the HTTP request
                data.addEvidence("header.user-agent",
                            "Mozilla/5.0 (Windows NT 10.0; Win64; x64) " +
                               "AppleWebKit/537.36 (KHTML, like Gecko) " +
                               "Chrome/98.0.4758.102 Safari/537.36");
                data.addEvidence("header.sec-ch-ua-mobile", "?0");
                data.addEvidence("header.sec-ch-ua",
                            "\\" Not A; Brand\\";v=\\"99\\", \\"Chromium\\";v=\\"98\\", " +
                               "\\"Google Chrome\\";v=\\"98\\"");
                data.addEvidence("header.sec-ch-ua-platform", "\\"Windows\\"");
                data.addEvidence("header.sec-ch-ua-platform-version", "\\"14.0.0\\"");
                // process evidence
                data.process();
                // get the results
                DeviceData device = data.get(DeviceData.class);
${lines.join("\r\n")}
            }
        }
    }
}`;

        return code;
    };

    render() {
        return (
            <div id={this.props.id} ref={this.props.refProp}>
                <h2 className="g-info__subheading">Java</h2>
                {this.getPackages()}
                <article className="g-example-block">
                    <div className="c-code">
                        <header className="c-code__header">
                            <h2 className="c-code__heading">Example Java code</h2>
                            <a href="#" className="c-copy-clipboard" onClick={this.copyCode}><img src="/images/icon-clipboard.svg" alt="Copy to clipboard" /></a>
                        </header>
                        <pre className="prettyprint">
                            <code className="lang-java c-code__block">
                                <div dangerouslySetInnerHTML={{ __html: this.getCode().replace(/</g, '&lt;').replace(/>/g, '&gt;') }} />
                            </code>
                        </pre>
                    </div>
                    <Output examples={this.props.examples} selectedProperties={this.selectedProperties} />
                </article>
            </div>);
    }
}
