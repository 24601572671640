import React, { Component } from 'react';
import { ClientSide } from './implementations/clientside/ClientSide';
import { ServerSide } from './implementations/serverside/ServerSide';
import { Analytics } from './implementations/analytics/Analytics';
import { Referrer } from './implementations/referer/Referrer';
import { matomoTrackCustomEvent } from './../../MatomoTracking'
 
export class Implement extends Component {
    displayName = Implement.name;

    constructor(props) {
        super(props);

        this.state = {
            active: 'ClientSide'
        };
    }

    hiddenProperties = [
        "device.javascripthardwareprofile",
        "location.javascript",
        "location_digitalelement.javascript"
    ];

    componentDidMount() {
        if ((this.props.referer === "" || this.props.referer === undefined) === false) {
            this.setState({
                active: 'Referrer'
            });
        }
    }

    runCodePrettify = () => {
        let style = document.createElement('link');
        style.rel = "stylesheet";
        style.type = 'text/css';
        style.href = '/google-code-prettify/prettify.css';
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '/google-code-prettify/prettify.js';
        script.async = true;
        const parent = (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]);
        parent.appendChild(style);
        parent.appendChild(script);
    }

    handleClick = (category) => {
        this.setState({ active: category });
        matomoTrackCustomEvent("Catagory Viewed: ", category);
    }

    render() {
        let properties = this.props.properties;
        let selectedProperties = this.props.selectedProperties;
        let resource = this.props.resource;
        let examples = this.props.examples;

        return (
            <main className="g-main">
                <div className="g-options">
                    <div className="g-panel g-panel--25">
                        <ul className="g-panel__list">
                            <ClientSide
                                handleClick={this.handleClick}
                                active={this.state.active}
                                properties={properties}
                                selectedProperties={selectedProperties}
                                hiddenProperties={this.hiddenProperties}
                                examples={examples}
                                resource={resource}
                                runCodePrettify={this.runCodePrettify}
                            />
                            <ServerSide
                                handleClick={this.handleClick}
                                active={this.state.active}
                                properties={properties}
                                selectedProperties={selectedProperties}
                                hiddenProperties={this.hiddenProperties}
                                examples={examples}
                                resource={resource}
                                runCodePrettify={this.runCodePrettify}
                            />
                            <Analytics
                                handleClick={this.handleClick}
                                active={this.state.active}
                                properties={properties}
                                selectedProperties={selectedProperties}
                                hiddenProperties={this.hiddenProperties}
                                examples={examples}
                                resource={resource}
                                runCodePrettify={this.runCodePrettify}
                            />
                            <Referrer
                                handleClick={this.handleClick}
                                active={this.state.active}
                                properties={properties}
                                selectedProperties={selectedProperties}
                                hiddenProperties={this.hiddenProperties}
                                examples={examples}
                                resource={resource}
                                referer={this.props.referer}
                                runCodePrettify={this.runCodePrettify}
                            />
                        </ul>
                    </div>
                </div>
            </main>
        );
    }
}