import React, { Component } from 'react';

export class Output extends Component {
    displayName = Output.name;

    constructor(props) {
        super(props);

        this.state = {
            outputData: {}
        };
    }

    componentDidMount() {
        var selectedProperties = this.props.selectedProperties;

        var keys = [];
        selectedProperties.forEach(prop => {
            var key = prop.split('.')[0].toLowerCase();
            if (keys.indexOf(key) === -1) {
                keys.push(key);
            }
        });

        window.fod.complete((data) => {
            this.setState({
                outputData: data
            });
        }, keys.join(','));
    }

    truncateString = (str, num) => {
        // If the length of str is less than or equal to num
        // just return str--don't truncate it.
        if (str === undefined || str === null || str.length <= num) {
            return str
        }
        // Return str truncated with '...' concatenated to the end of str.
        return str.slice(0, num) + '...'
    }

    render() {
        var outputData = this.state.outputData;

        let outputRows = this.props.selectedProperties
            .filter(property => property.toLowerCase().indexOf("javascript") === -1 && property.toLowerCase().indexOf("hardware.profiles") === -1)
            .map((property, i) => {
            let example = outputData[property.split('.')[0].toLowerCase()];
            if (example !== undefined) {
                let key = property.split('.')[1].toLowerCase();
                
                var value = "Null";

                if (example[key] === null || example[key] === undefined) {
                    value = "Null";
                } else {
                    if (Array.isArray(example[key])) {
                        if (example[key].some(s => typeof (s) == "object")) {
                            value = example[key].map(v => v["value"]).filter(v => v != "Unknown").toString()
                        } else {
                            value = ((example[key] === null || example[key] === undefined) ? "Null" : example[key].toString());
                        }
                    } else if (typeof (example[key]) == "object") {
                        var tmp = []
                        for (const [key, value] of Object.entries(example[key])) {
                            tmp.push(`${key}: ${value}`);
                        }
                        value = tmp.join(', ');
                    } else {
                        value = example[key].toString();
                    }
                }
                // Remove non-alpha numeric chars from the key
                var sanitizedKey = key.replace(/[^0-9a-z]/gi, '');
                // Build the names we can use for the HTML row elements
                var rowKeyA = `${sanitizedKey}-name`;
                var rowKeyB = `${sanitizedKey}-example`;

                return (
                    [<tr key={rowKeyA}>
                        <th className="c-outputs__device">{property.split('.')[1]}:</th>
                    </tr>,
                    <tr key={rowKeyB}>
                        <td><span className="c-outputs__example">{this.truncateString(value, 40)}</span></td>
                    </tr>]
                );
            } else { return null;}
        }).filter(r => r !== null);

        return(
            <div className="c-outputs">
                <div className="c-outputs__header">
                    <h3 className="c-outputs__heading">Outputs:</h3>
                </div>
                <table className="c-outputs__table">
                    <tbody>
                        {outputRows}
                    </tbody>
                </table>
            </div>
        );
    }
}
