import React, { Component } from 'react';

export class LicenseKeyList extends Component {
    displayName = LicenseKeyList.name;

    constructor(props) {
        super(props);
    }

    render() {
        let productKeys = this.props.productKeys;

        if (productKeys !== undefined &&
            productKeys.length > 0)
            return (
                <div>
                    <div className="g-table g-table--overflow">
                        <table className="c-table c-table--packages">
                            <thead className="">
                                <tr className="">
                                    <th className="">Key</th>
                                    <th className="">Product</th>
                                    <th className="">Requests (per month)</th>
                                    <th className="">Terms agreed</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {productKeys.map((key, i) => (
                                    <tr key={i} className="">
                                        <td className="">{key.value.substring(0, 6)}***{key.value.substring(key.value.length - 6, key.value.length)}</td>
                                        <td className="">{key.product}</td>
                                        <td className="">{key.requests}</td>
                                        <td className="">{key.terms !== '' ? <a href={key.terms} target="blank">{key.product} Terms</a> : key.requests === 'Add-on' ? <span>See main subscription terms</span> : null }</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        else return null;
    }
}