import React, { Component } from 'react';
import { Output } from './../Output';

export class PHP extends Component {
    displayName = PHP.name;

    constructor(props) {
        super(props);

        this.selectedProperties = this.props.selectedProperties.filter(p => p.startsWith("ip.") === false &&
            p.startsWith("location_consolidated.") === false &&
            p.startsWith("country_list.") === false &&
            p.startsWith("local_countryname.") === false &&
            p.startsWith("neighbouring_countries.") === false);
    }

    copyCode = (e) => {
        e.preventDefault();
        this.copy(this.getCode());
    };

    copy = (data) => {
        var dummy = document.createElement('textarea');
        document.body.appendChild(dummy);
        dummy.value = data;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert('Copied!');
    };

    addReverseGeoLocationEvidence = (evidenceList) => {
        var lat = '$FlowData->evidence->set("query.51D_Pos_latitude", "51.458048");';
        var lon = '$FlowData->evidence->set("query.51D_Pos_longitude", "-0.9822207999999999");';

        if (evidenceList.indexOf(lat) === -1) {
            evidenceList.push(lat);
        }

        if (evidenceList.indexOf(lon) === -1) {
            evidenceList.push(lon);
        }
    }

    getPackages = () => {
        let dataKeys = [];

        this.props.selectedProperties.forEach(prop => {
            var dataKey = prop.substring(0, prop.lastIndexOf('.'));
            if (dataKeys.indexOf(dataKey) === -1) {
                dataKeys.push(dataKey);
            }
        });

        let elements = [];

        dataKeys.forEach(dataKey => {
            switch (dataKey) {
                case 'device':
                    elements.push(this.props.newPackage(this.copy, 'Composer - Device Detection', 'composer require 51degrees/fiftyone.devicedetection', 'fod-dd'));
                    break;
                case 'location':
                    elements.push(this.props.newPackage(this.copy, 'Composer - Geo Location', 'composer require 51degrees/fiftyone.geolocation', 'fod-geo'));
                    break;
                default:
                    break;
            }
        });

        return elements;
    }

    getCode = () => {
        let addToArray = this.props.addToArray;
        let dataKeys = this.props.dataKeys;

        let imports = [];
        let engines = [];
        let addEngines = [];
        let evidence = [];

        dataKeys.forEach(dataKey => {
            switch (dataKey) {
                case 'device':
                    addToArray(imports, 'use fiftyone\\pipeline\\devicedetection\\DeviceDetectionCloud;');
                    engines.push(`$deviceDetection = new DeviceDetectionCloud();`);
                    addEngines.push('->add($deviceDetection)');
                    evidence.push(`$FlowData->evidence->set("header.user-agent", 
                "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G960U) " .
                "AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/10.1 " .
                "Chrome/71.0.3578.99 Mobile Safari/537.36");`);
                    break;
                case 'hardware.Profiles':
                    break;
                case 'location':
                    addToArray(imports, 'use fiftyone\\pipeline\\geolocation\\GeoLocationCloud;');
                    engines.push(`$fodGeolocation = new GeoLocationCloud(array("locationProvider" => "fiftyonedegrees"));`);
                    addEngines.push('->add($fodGeolocation)');
                    this.addReverseGeoLocationEvidence(evidence);
                    break;
                case 'local_countryname':
                    break;
                case 'location_consolidated':
                    break;
                case 'neighbouring_countries':
                    break;
                default:
                    break;
            }
        });

        let lines = this.selectedProperties
            .filter(prop =>
                prop.startsWith("hardware.Profiles") === false &&
                prop.startsWith("device") &&
                this.props.hiddenProperties.indexOf(prop.toLowerCase()) === -1)
            .map((selectedProperty, i) => {
                var prop = selectedProperty.split('.');

                // access properties with special characters using getInternal 
                // method
                var propertyName = prop[1].toLowerCase();
                if (propertyName.indexOf("-") !== -1 || propertyName.indexOf("/") !== -1) {
                    propertyName = `getInternal('${propertyName}')`;
                }
                return `        $output("${selectedProperty}: ".$device->${propertyName}->value);`;
            });
        if (lines.length === 0) {
            // No device detection properties, so just add IsMobile.
            lines[0] = `        $output(json_encode("device.IsMobile: ".$device->ismobile->value));`;
        }

        //TODO: Enable once property keyed engine added to PHP.
        //let hardwareProp = this.props.selectedProperties.indexOf("hardware.Profiles") !== -1;
        //let hardwareProps = this.props.selectedProperties.filter(prop => prop.startsWith("hardware.Profiles."));
        //if (hardwareProp && hardwareProps.length > 0) {
        //    lines.push(" ");
        //    lines.push("foreach($FlowData->hardware->Profiles as $profile)");
        //    hardwareProps.map((selectedProperty) => {
        //        let property = selectedProperty.split('.').slice(-1)[0];
        //        lines.push(`    echo "${property}: ".$profile -> { '${property}'}."<br>"; `);
        //    });
        //    lines.push("}");
        //}

        let code = `<?php
error_reporting(E_ALL ^ (E_WARNING | E_DEPRECATED));
require(__DIR__ . "/vendor/autoload.php");
use fiftyone\\pipeline\\devicedetection\\DeviceDetectionPipelineBuilder;
use fiftyone\\pipeline\\core\\PipelineBuilder;
use fiftyone\\pipeline\\core\\Logger;

class ConfiguratorConsole

{
    public function run($resourceKey, $logger, callable $output)
    {
        // Create a minimal pipeline to access the cloud engine
        // you only need one pipeline for multiple requests
        $pipeline = (new DeviceDetectionPipelineBuilder(array("resourceKey" => $resourceKey)))
            ->addLogger($logger)
            ->build();
        // Get a flow data from the singleton pipeline for each detection
        $data = $pipeline->createFlowData();

        // Add the evidence values to the flow data
        $data->evidence->set(
            "header.user-agent",
            "Mozilla/5.0 (Windows NT 10.0; Win64; x64) ".
            "AppleWebKit/537.36 (KHTML, like Gecko) ".
            "Chrome/98.0.4758.102 Safari/537.36");
        $data->evidence->set(
            "header.sec-ch-ua-mobile",
            "?0");
        $data->evidence->set(
            "header.sec-ch-ua",
            "\\" Not A; Brand\\";v=\\"99\\", \\"Chromium\\";v=\\"98\\", ".
            "\\"Google Chrome\\";v=\\"98\\"");
        $data->evidence->set(
            "header.sec-ch-ua-platform",
            "\\"Windows\\"");
        $data->evidence->set(
            "header.sec-ch-ua-platform-version",
            "\\"14.0.0\\"");

        // Process the flow data.
        $data->process();
        // Get the results.
        $device = $data->device;
${lines.join("\r\n")}

    }
};
function main($argv)
{
    $resourceKey = "${this.props.resource.key}";
     $logger = new Logger("info");
    (new ConfiguratorConsole())->run($resourceKey, $logger, function($str) { echo $str."\\n"; });
}
main(isset($argv) ? array_slice($argv, 1) : null);
?>
`

        return code;
    };

    render() {
        return (
            <div id={this.props.id} ref={this.props.refProp}>
                <h2 className="g-info__subheading">PHP</h2>
                {this.getPackages()}
                <article className="g-example-block">
                    <div className="c-code">
                        <header className="c-code__header">
                            <h2 className="c-code__heading">Example PHP code</h2>
                            <a href="#" className="c-copy-clipboard" onClick={this.copyCode}><img src="/images/icon-clipboard.svg" alt="Copy to clipboard" /></a>
                        </header>
                        <pre className="prettyprint">
                            <code className="lang-php c-code__block">
                                <div dangerouslySetInnerHTML={{ __html: this.getCode().replace(/</g, '&lt;').replace(/>/g, '&gt;') }} />
                            </code>
                        </pre>
                    </div>
                    <Output examples={this.props.examples} selectedProperties={this.selectedProperties} />
                </article>
            </div>);
    }
}
