import React, { Component } from 'react';

export class AddDomains extends Component {
    displayName = AddDomains.name;

    constructor(props) {
        super(props);

        this.state = {
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const { updateDomains } = this.props;

        updateDomains(event.target.value);
    }

    render() {
        var domains = this.props.domains;

        return (
            <article className="c-card ">
                <p className="c-card__heading">Add domains to help us prevent unauthorized use of your licenses, please note that your configuration will only work on these domains. We advise that a domain is provided for production configurations, if you are testing locally you will need to leave this field blank.</p>
                <p>Multiple domains can be comma separated (e.g. localhost, www.mydomain.com). The Wildcard character can be used to match anything (e.g. *.mydomain.com)</p>
                <div className="b-form-group">
                    <input type="text" className="b-input b-input--block" id="input" placeholder="e.g. www.mydomain.com, staging.mydomain.com" aria-label="Domains" value={domains} onChange={this.handleChange} />
                </div>
            </article>
        );
    }
}