import React, { Component } from 'react';

export class Message extends Component {
    displayName = Message.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <main className="g-main">
                <div className="g-panel g-panel--100">
                    <article className="g-info">
                        <div>
                            <p>{this.props.message}</p>
                        </div>
                    </article>
                </div>
            </main>
        );
    }
}