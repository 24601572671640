import React, { Component } from 'react';

export class SearchItem extends Component {
    displayName = SearchItem.name;

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.onClick(this.props.value);
    }

    render() {
        return (
            <li className="c-search__item">
                <a onClick={this.handleClick}>
                    {this.props.value}
                    <span className="c-option__additional">
                        <span className="c-option__vendor"><img src={this.props.vendor.iconUrl} alt={this.props.vendor.name} width="13px" height="13px"/></span>
                    </span>
                </a>
            </li>
        );
    }
}