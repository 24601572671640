import React, { Component } from 'react';
import { Output } from './../Output';

export class Python extends Component {
    displayName = Python.name;

    constructor(props) {
        super(props);

        this.selectedProperties = this.props.selectedProperties.filter(p => p.startsWith("ip.") === false &&
            p.startsWith("location_consolidated.") === false &&
            p.startsWith("country_list.") === false &&
            p.startsWith("local_countryname.") === false &&
            p.startsWith("neighbouring_countries.") === false);
    }

    copyCode = (e) => {
        e.preventDefault();
        this.copy(this.getCode());
    };

    copy = (data) => {
        var dummy = document.createElement('textarea');
        document.body.appendChild(dummy);
        dummy.value = data;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert('Copied!');
    };

    getPackages = () => {
        let dataKeys = [];

        this.props.selectedProperties.forEach(prop => {
            var dataKey = prop.substring(0, prop.lastIndexOf('.'));
            if (dataKeys.indexOf(dataKey) === -1) {
                dataKeys.push(dataKey);
            }
        });

        let elements = [];

        dataKeys.forEach(dataKey => {
            switch (dataKey) {
                case 'device':
                    elements.push(this.props.newPackage(this.copy, 'PyPi - Device Detection', 'pip install fiftyone-devicedetection', 'fod-dd'));
                    break;
                case 'location':
                    elements.push(this.props.newPackage(this.copy, 'PyPi - Geo Location', 'pip install fiftyone-location', 'fod-geo'));
                    break;
                default:
                    break;
            }
        });

        return elements;
    }

    addReverseGeoLocationEvidence = (evidenceList) => {
        var lat = 'flowData.evidence.add("query.51D_Pos_latitude", "51.458048")';
        var lon = 'flowData.evidence.add("query.51D_Pos_longitude", "-0.9822207999999999")';

        if (evidenceList.indexOf(lat) === -1) {
            evidenceList.push(lat);
        }

        if (evidenceList.indexOf(lon) === -1) {
            evidenceList.push(lon);
        }
    }

    getCode = () => {
        let addToArray = this.props.addToArray;
        let dataKeys = this.props.dataKeys;

        let imports = [];
        let engines = [];
        let addEngines = [];
        let evidence = [];

        dataKeys.forEach(dataKey => {
            switch (dataKey) {
                case 'device':
                    addToArray(imports, 'from fiftyone_devicedetection.devicedetection_pipelinebuilder import DeviceDetectionCloud');
                    engines.push(`deviceDetectionCloudEngine = DeviceDetectionCloud()`);
                    addEngines.push('.add(deviceDetectionCloudEngine)');
                    evidence.push(`flowData.evidence.add("header.user-agent", 
                "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G960U) " +
                "AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/10.1 " +
                "Chrome/71.0.3578.99 Mobile Safari/537.36")`);
                    break;
                case 'hardware.Profiles':
                    addToArray(imports, 'from fiftyone_devicedetection.hardwareprofile_cloud import HardwareProfileCloud');
                    engines.push(`hardwareProfileEngine = HardwareProfileCloud()`);
                    addEngines.push('.add(hardwareProfileEngine)');
                    evidence.push('flowData.evidence.add("query.tac", "35925406")');
                    evidence.push('# flowData.evidence.add("query.nativemodel", "iPhone11,8")');
                    break;
                case 'location':
                    addToArray(imports, 'from fiftyone_location.location_pipelinebuilder import LocationCloud');
                    engines.push(`fodLocationCloudEngine = LocationCloud({"locationProvider": "fiftyonedegrees"})`);
                    addEngines.push('.add(fodLocationCloudEngine)');
                    this.addReverseGeoLocationEvidence(evidence);
                    break;
                case 'local_countryname':
                    break;
                case 'location_consolidated':
                    break;
                case 'neighbouring_countries':
                    break;
                default:
                    break;
            }
        });

        let lines = this.selectedProperties
            .filter(prop =>
                prop.startsWith("hardware.Profiles") === false &&
                prop.startsWith("device") &&
                this.props.hiddenProperties.indexOf(prop.toLowerCase()) === -1)
            .map((selectedProperty, i) => {
                var prop = selectedProperty.split('.');

                // access properties with special characters using getInternal 
                // method
                var propertyName = prop[1].toLowerCase();
                if (propertyName.indexOf("-") !== -1 || propertyName.indexOf("/") !== -1) {
                    propertyName = `get_internal('${propertyName}')`;
                }
                return `        output(f"${selectedProperty}: {device.${propertyName}.value()}")`;
            });
        if (lines.length === 0) {
            lines[0] = `        output(f"device.IsMobile: {device.ismobile.value()}")`
        }

        //let hardwareProp = this.props.selectedProperties.indexOf("hardware.Profiles") !== -1;
        //let hardwareProps = this.props.selectedProperties.filter(prop => prop.startsWith("hardware.Profiles."));
        //if (hardwareProp && hardwareProps.length > 0) {
        //    lines.push('');
        //    lines.push("for profile in flowData.hardware.profiles:");
        //    hardwareProps.map((selectedProperty) => {
        //        let property = selectedProperty.split('.').slice(-1)[0];
        //        lines.push(`    print("${property}: " + check_property(profile["${property.toLowerCase().replace(/-|\//g, '')}"]))`);
        //    });
        //}

        let code = `import sys
from fiftyone_pipeline_core.logger import Logger
from fiftyone_devicedetection.devicedetection_pipelinebuilder import DeviceDetectionPipelineBuilder

class ConfiguratorConsole():
    def run(self, resource_key, logger, output):

        # Create a minimal pipeline to access the cloud engine
        # you only need one pipeline for multiple requests
        pipeline = DeviceDetectionPipelineBuilder(
            resource_key = resource_key).add_logger(logger).build()

        # Get a flow data from the singleton pipeline for each detection
        data = pipeline.create_flowdata()

        # Add the evidence values to the flow data
        data.evidence.add(
            "header.user-agent",
            "Mozilla/5.0 (Windows NT 10.0; Win64; x64) "
            "AppleWebKit/537.36 (KHTML, like Gecko) "
            "Chrome/98.0.4758.102 Safari/537.36")
        data.evidence.add(
            "header.sec-ch-ua-mobile",
            "?0")
        data.evidence.add(
            "header.sec-ch-ua",
            "\\" Not A; Brand\\";v=\\"99\\", \\"Chromium\\";v=\\"98\\", "
            "\\"Google Chrome\\";v=\\"98\\"")
        data.evidence.add(
            "header.sec-ch-ua-platform",
            "\\"Windows\\"")
        data.evidence.add(
            "header.sec-ch-ua-platform-version",
            "\\"14.0.0\\"")

        # Process the flow data.
        data.process()

        # Get the results.
        device = data.device

${lines.join("\r\n")}

def main(argv):
    resource_key = "${this.props.resource.key}"

    # Configure a logger to output to the console.
    logger = Logger(min_level="info")

    ConfiguratorConsole().run(resource_key, logger, print)

if __name__ == "__main__":
    main(sys.argv[1:])`
        return code;
    };

    render() {
        return (
            <div id={this.props.id} ref={this.props.refProp}>
                <h2 className="g-info__subheading">Python</h2>
                {this.getPackages()}
                <article className="g-example-block">
                    <div className="c-code">
                        <header className="c-code__header">
                            <h2 className="c-code__heading">Example Python code</h2>
                            <a href="#" className="c-copy-clipboard" onClick={this.copyCode}><img src="/images/icon-clipboard.svg" alt="Copy to clipboard" /></a>
                        </header>
                        <pre className="prettyprint">
                            <code className="lang-python c-code__block">
                                <div dangerouslySetInnerHTML={{ __html: this.getCode().replace(/</g, '&lt;').replace(/>/g, '&gt;') }} />
                            </code>
                        </pre>
                    </div>
                    <Output examples={this.props.examples} selectedProperties={this.selectedProperties} />
                </article>
            </div>);
    }
}
