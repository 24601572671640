import React, { Component } from 'react';
import { GoogleAnalytics } from './GoogleAnalytics';

export class AnalyticsImplementation extends Component {
    displayName = AnalyticsImplementation.name;

    constructor(props) {
        super(props);
        this.state = {
            active: this.props.active
        };

        this.googleRef = React.createRef();

        let url = "https://cloud.51degrees.com";
        let headerNames = [
            'ch-ua-full-version-list',
            'ch-ua-model',
            'ch-ua-platform',
            'ch-ua-platform-version'
        ];
        let elementValue = '';
        headerNames.forEach((feat) => {
            if (elementValue.length != 0) {
                elementValue += '; ';
            }
            elementValue += 'sec-' + feat + ' ' + url;
        });
        this.permissionsElement = '<meta http-equiv="Delegate-CH" content="' + elementValue + '"/>';
    }

    handleClick = (e) => {
        e.preventDefault();
        const { handleClick } = this.props;
        handleClick('');
    };

    scrollToTop = () => {
        if (this.refs.scroller) {
            this.refs.scroller.scrollTop = 0;
        }
    };

    scrollToMyRef = (e, ref) => {
        e.preventDefault();
        ref.current.scrollIntoView();
    }

    render() {
        let classes = "g-panel g-panel--75";

        if (this.props.active) {
            classes += " is-active";
        }

        return (
            <div className={classes}>
                <header className="c-category-header">
                    <a href="#" className="c-category-header__link is-reversable" onClick={this.handleClick}>Back</a> / Google Analytics
                    </header>
                <article className="g-info" ref="scroller">
                    <h2 className="g-info__title">Analytics</h2>
                    <nav className="c-sub-nav">
                        <h3 className="c-sub-nav__heading">On this page</h3>
                        <p>Understand how physical screen size, device price and page performance impact conversion and goal completion.</p>
                        <ul className="c-sub-nav__list">
                            <li className="c-sub-nav__link"><a href="#googleanalytics" className="c-sub-nav__item" onClick={(e) => { this.scrollToMyRef(e, this.googleRef); }}>Google Analytics</a></li>
                        </ul>
                    </nav>
                    <GoogleAnalytics
                        refProp={this.googleRef}
                        id="googleanalytics"
                        properties={this.props.properties}
                        selectedProperties={this.props.selectedProperties}
                        hiddenProperties={this.props.hiddenProperties}
                        resource={this.props.resource}
                        permissionsElement={this.permissionsElement}
                    />
                    <h2 className="g-info__subheading">Benefits of Analytics implementations</h2>

                    <div className="g-info__description">
                        <p><em>Richer Context:</em> Understand how physical screen size, device price and page performance impact conversion and goal completion.</p>
                        <p><em>User Experience:</em> Discover insights, test, and learn to continually improve with richer data from Enhanced Analytics.</p>
                        <p><em>Collaboration:</em> Easy to integrate with existing business intelligence tools including Google Analytics, Omniture, Coremetrics and Webtrends.</p>
                    </div>
                    <a className="c-top-link hide" href="#" id="js-top" onClick={(e) => { e.preventDefault(); this.scrollToTop(); }}>
                        <img src="/images/back-to-top.svg" alt="Back to top" className="c-top-link__image" />
                        <span className="c-top-link__label">Back to top</span>
                    </a>
                </article>
            </div>);
    }
}
