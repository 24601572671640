import React, { Component } from 'react';

import { ConfigureNav } from './Navs/ConfigureNav';
import { DetailsNav } from './Navs/DetailsNav';
import { ImplementNav } from './Navs/ImplementNav';
import { ShareNav } from './Navs/ShareNav';

export class NavMenu extends Component {
    displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.state = {
            section: this.props.section
        };
    }

    getMenu = () => {
        switch (this.props.section) {
            case 1:
                return <ConfigureNav next={this.props.next} selectedProperties={this.props.selectedProperties} />;
            case 2:
                return <DetailsNav
                    updateTerms={this.props.updateTerms}
                    termsAgreed={this.props.termsAgreed}
                    next={this.props.next}
                    canContinue={this.props.canContinue}
                    previous={this.props.previous}
                    permittedLicenseTypes={this.props.permittedLicenseTypes}
                    newLicenseTypes={this.props.newLicenseTypes}
                    validateDetails={this.props.validateDetails} />;
            case 3:
                return <ImplementNav next={this.props.next} previous={this.props.previous} implementRecap={this.props.implementRecap} />;
            case 4:
                return <ShareNav previous={this.props.previous} />;
            default:
                return null;
        }
    }

    render() {
        return this.getMenu();
    }
}