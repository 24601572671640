import React, { Component } from 'react';

export class GoogleAnalytics extends Component {
    displayName = GoogleAnalytics.name;

    constructor(props) {
        super(props);
        this.updateTrackingId = this.updateTrackingId.bind(this);
        this.state = {
            googleTrackingID: 'G-XXXXXXXXXX'
        };
    }

    updateTrackingId = (event) => {
        this.setState({ googleTrackingID: event.target.value });
    };

    copyCode = (e) => {
        e.preventDefault();
        var dummy = document.createElement('textarea');
        document.body.appendChild(dummy);
        dummy.value = this.getCode();
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert('Copied!');
    };

    getCode = () => {
        let properties = this.props.properties;
        let delayedProperties = properties.filter(prop => prop.DelayExecution).map(prop => prop.Id.toLowerCase());

        let delayedDataKeys = [];
        let delayedLines = [];
        let maps = [];
        let events = [];
        let delayedEvents = [];
        var i = 1;

        this.props.selectedProperties
        .filter(prop => prop.startsWith("hardware.Profiles") === false &&
            this.props.hiddenProperties.indexOf(prop.toLowerCase()) === -1)
        .forEach((selectedProperty) =>
        {

            if (i > 20) return;

            let property = properties.find(prop => { return prop.Id.toUpperCase() === selectedProperty.toUpperCase(); });

            if (property) {
                let delayed = false;
                if (property.EvidenceProperties && property.EvidenceProperties.length > 0) {
                    property.EvidenceProperties.forEach(prop => {
                        if (delayedProperties.indexOf(property.VendorId + "." + prop) !== -1) {
                            delayed = true;
                            if (delayedDataKeys.indexOf(property.VendorId) === -1)
                                delayedDataKeys.push(property.VendorId);
                        }
                    });
                }

                if (delayed) {
                    delayedLines.push(`addText("${property.Name}: " + data.${property.ValuePath});`);
                    delayedEvents.push(`'${property.Name.toLowerCase()}' : data.${property.ValuePath},`);
                } else if (property.Name.toLowerCase().indexOf("javascript") === -1) {
                    events.push(`'${property.Name.toLowerCase()}' : data.${property.ValuePath},`);
                }

                if (property.Name.toLowerCase().indexOf("javascript") === -1) {
                    maps.push(`'dimension${i++}' : '${property.Name.toLowerCase()}',`);
                }
            }
        });

        let code = `<!DOCTYPE html>
<html>
    <head>
        ${this.props.permissionsElement}
    </head>
    <body>
        <h1>51Degrees cloud example</h1>
        <button type="button" onclick="buttonClicked()">Use my location</button>
        <div id="htmlExampleOutput"></div>
    </body>
    <!-- 51Degrees include -->
    <script async src="${this.props.resource}"></script>
    <!-- End 51Degrees include -->
    <!-- Global site tag (gtag.js) - Google Analytics -->
    <!-- This has been generated using the guide here https://developers.google.com/analytics/devguides/collection/ga4/event-parameters?client_type=gtag -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=${this.state.googleTrackingID}"></script>
    <script>
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        window.onload=function (){
            var  update = function(data){
                gtag('event', 'fod', {
                    ${events.join('\r\n                ')}
                });
            };
            fod.complete(update);
        }`;

        if (delayedLines.length > 0) {
            code += `

        buttonClicked = function() {
            fod.complete(function (data) {
                ${delayedLines.join('\r\n            ')}
            
                gtag('event', 'delayed', {
                    ${delayedEvents.join('\r\n                ')}
                });
            }, '${delayedDataKeys.join(",")}');
        }`;
        }

        code += `

        addText = function(text){
            document.getElementById("htmlExampleOutput").innerHTML+=text + "<br>";
        }
    </script>
</html>
`;

        return code;
    };

    render() {
        var i = 1;
        return (
            <div id={this.props.id} ref={this.props.refProp}>
                <h3 className="g-info__subheading">Google Analytics</h3>
                <p>To enable google analytics integration you must first set up custom dimensions in your Google Analytics account. Google Analytics only supports 20 custom dimensions per Tracking ID, if you have aded more than 20 properties then we will omit any properties extra to that amount in the following example. If you have already set up custom dimensions for other purposes then that will limit the number of additional custom dimensions you can configure and you may also have to tweak this example. Set up the following custom dimensions in this exact order:</p>
                <ul>
                    {
                        this.props.selectedProperties
                            .filter(prop => prop.startsWith("hardware.Profiles") === false &&
                                prop.toLowerCase().indexOf("javascripthardwareprofile") === -1 &&
                                prop.toLowerCase().indexOf("location.javascript") === -1 &&
                                prop.toLowerCase().indexOf("digitalelement.javascript") === -1)
                            .map(item => {
                            if (i > 20) return undefined;
                            i++;
                            return <li key={item.split('.')[1].toLowerCase()}>{item.split('.')[1].toLowerCase()}</li>;
                        })
                    }
                </ul>
                <p>See this guide at <a href="https://support.google.com/analytics/answer/14240153">support.google.com</a> on how to add custom dimensions.</p>
                <article className="c-card {{ modifier }}">
                    <header className="c-card__header">
                        <h3 className="c-card__heading">You will need to add your Google Analytics Tracking ID for the example to work. We won't store this information.</h3>
                    </header>
                    <div className="b-form-group b-form-group--with-icon">
                        <img src="/images/icon-feature-vendor-google.png" className="b-icon" alt="Google logo" />
                        <input type="text" className="b-input b-input--block" id="input" placeholder="Enter your Google Analytics Tracking ID" onChange={this.updateTrackingId} />
                    </div>
                </article>
                <p>Copy and paste this code as the first item into the &lt;HEAD&gt; of every web page that you want to track.</p>
                <article className="c-code">
                    <header className="c-code__header">
                        <h2 className="c-code__heading">Example JS code</h2>
                        <a href="#" className="c-copy-clipboard" onClick={this.copyCode}><img src="/images/icon-clipboard.svg" alt="Copy to clipboard" /></a>
                    </header>
                    <pre className="prettyprint">
                        <code className="lang-html c-code__block">
                            <div dangerouslySetInnerHTML={{ __html: this.getCode().replace(/</g, '&lt;').replace(/>/g, '&gt;') }} />
                        </code>
                    </pre>
                </article>
            </div>);
    }
}
