import React, { Component } from 'react';

export class VendorListItem extends Component {
    displayName = VendorListItem.name

    constructor(props) {
        super(props);

        this.state = {
            checked: this.props.checked
        };
    }

    _handleChange = () => {
        const { vendor, onChange } = this.props;
        this.setState({ checked: !this.state.checked });
        onChange(vendor.map);
    }

    render() {
        return (
            <li className="c-filter__item">
                <span className="c-select">
                    <input type="checkbox" className="c-select__input is-selectable" id={this.props.vendor.map} data-property={this.props.vendor.map} onChange={this._handleChange} checked={this.props.checked} />
                    <label className="c-select__label" htmlFor={this.props.vendor.map}><span className="c-select__text">{this.props.vendor.name}</span></label>
                </span>
            </li>
         );
    }
}