import React, { Component } from 'react';

import { AddDomains } from './AddDomains';
import { Register } from './Register';
import { LicenseKey } from './LicenseKey';
import { matomoTrackCustomEvent } from './../../MatomoTracking'

export class Details extends Component {
    displayName = Details.name;

    constructor(props) {
        super(props);

        this.state = {
            free: false
        };


    }

    componentDidMount() {
        const { newLicenseType, newLicenseTypes, permittedLicenseTypes } = this.props;
        if (newLicenseType === newLicenseTypes.FREE && permittedLicenseTypes.indexOf(newLicenseTypes.NONE) === -1) {
            this.setState({ free: true });
        }
    }

    componentWillUnmount() {
        const { register } = this.props;
        register();
    }

    updateFree = () => {
        const { setNewLicenseType, newLicenseTypes, removePermittedLicenseType, addPermittedLicenseType, selectedProperties, paidProperties, properties } = this.props;
        
        var isFree = !this.state.free;
        if (isFree) {
            if (paidProperties.length > 0) {
                // exclude properties which are dependencies of other properties and exclude sub properties
                var paidPropertyNames = paidProperties.map(prop => prop.Id);

                var dependencies = [];
                var subProperties = [];

                properties.filter(p => p.ItemProperties != null && p.ItemProperties.length > 0).forEach(prop => {
                    prop.ItemProperties.forEach(iProp => {
                        if (selectedProperties.indexOf(iProp.Id) !== -1) {
                            subProperties.push(iProp.Id);
                        }
                    });
                });

                paidProperties.forEach(prop => {

                    if (prop.Dependencies !== null) {
                        prop.Dependencies.forEach(dep => {
                            if (paidPropertyNames.indexOf(dep) === -1 && dependencies.indexOf(dep) === -1) {
                                dependencies.push(dep);
                            }
                        });
                    }

                });

                var numSelectedPaidProperties = selectedProperties.length - dependencies.length - subProperties.length;
                if (paidProperties.length === numSelectedPaidProperties) {
                    window.alert("You have only selected paid properties which you will not be able to access. Please supply the required license key(s) to continue.");
                    return;
                }
                var ok = window.confirm("You have selected paid properties which you will not be able to access and you will be limited to 100,000 requests per month. Is this ok?");
                if (!ok) {
                    return;
                }
            }
            setNewLicenseType(newLicenseTypes.FREE);
            addPermittedLicenseType(newLicenseTypes.FREE)
            removePermittedLicenseType(newLicenseTypes.NONE);
        } else {
            setNewLicenseType(newLicenseTypes.NONE);
            addPermittedLicenseType(newLicenseTypes.NONE)
            removePermittedLicenseType(newLicenseTypes.FREE);
        }
        this.setState({ free: isFree });
        matomoTrackCustomEvent("Free Product Selected: ", isFree);
    }

    render() {
        let selectedProperties = this.props.selectedProperties.filter(prop => prop.startsWith("hardware.Profiles") === false);
        let paidProperties = this.props.paidProperties;
        let availableProperties = this.props.availableProperties;
        let vendors = this.props.vendors;
        let permittedTypes = this.props.permittedLicenseTypes;
        let selectedType = this.props.newLicenseType;
        let newLicenseTypes = this.props.newLicenseTypes;

        let fiftyone = null;
        for (var i = 0; i < vendors.length; i++) {
            var vendor = vendors[i];
            if (vendor.name !== undefined && vendor.name.startsWith("51Degrees")) {
                fiftyone = vendor;
            }
        }

        var requriedVendors = [];
        paidProperties.forEach((property) => {
            var vendor = vendors.find(v => v.map === property.VendorId);
            var propertyName = property.Id.split('.').slice(-1)[0];
            var available = availableProperties.Products[property.VendorId] && availableProperties.Products[property.VendorId].Properties.find(p => p.Name === propertyName);
            var newName = vendor.name === "51Degrees: Device" || vendor.name === "51Degrees: Location" ? "51Degrees Subscription" : vendor.name
            if (vendor.addon === true) {
                newName += '*';
            }
            if (requriedVendors.find(v => v.name === newName) === undefined) {
                requriedVendors.push({ name: newName, available: available, addon: vendor.addon });
            }
        });

        let freeMessage = "Good news, all the properties you've selected are free of charge for up to 100k requests per month. For more requests you'll need a License key(s). If you already have a subscription, enter your License key(s) below - otherwise you can buy what you need on our ";
        let premiumMessage = "You've selected some of our premium, paid for properties, which you'll need a License key to access. If you already have a subscription, enter your License key(s) below - otherwise you can buy what you need on our ";
        let premiumOnlyMessage = "You've only selected our premium, paid for properties, which you'll need a License key to access. If you already have a subscription, enter your License key(s) below - otherwise you can buy what you need on our ";

        let message = freeMessage;
        if (paidProperties.length > 0) {
            if (paidProperties.length === selectedProperties.length) {
                message = premiumOnlyMessage;
            } else {
                message = premiumMessage;
            }
        }

        return (
            <main className="g-main" >
                <div className="g-panel g-panel--100">
                    <article className="g-info">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h2 className="g-info__title">Review your configuration</h2>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <article className="c-card c-card--highlight">
                                        <header className="c-card__header">
                                            <h3 className="c-card__heading">{message}<a href="https://51degrees.com/pricing" rel="noopener noreferrer" target="_blank" className="b-link">Pricing Page</a></h3>
                                        </header>
                                        <span className="c-select  c-select--stacked">
                                            <input type="checkbox" className="c-select__input is-selectable" id="freeCheck" data-property="" checked={this.state.free} onChange={this.updateFree} />
                                            <label className="c-select__label" htmlFor="freeCheck">
                                                <span className="c-select__text">Free product is enough for me</span>
                                            </label>
                                        </span>
                                    </article>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <h3 className="g-info__heading">Already have a license key?</h3>
                                    <LicenseKey id={newLicenseTypes.NONE}
                                        selected={selectedType === newLicenseTypes.NONE}
                                        disabled={permittedTypes.includes(newLicenseTypes.NONE) === false}
                                        addKey={this.props.addKey}
                                        productKeys={this.props.productKeys}
                                        fiftyone={fiftyone}
                                        requriedVendors={requriedVendors}
                                        loadingLicense={this.props.loadingLicense}
                                        setLoading={this.props.setLoading}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <h3 className="g-info__heading">Register your details</h3>
                                    <Register clientEmail={this.props.clientEmail}
                                        receiveMarketingMessages={this.props.receiveMarketingMessages}
                                        receiveProductUpdates={this.props.receiveProductUpdates}
                                        consentToMarketing={this.props.consentToMarketing}
                                        consentToProductUpdates={this.props.consentToProductUpdates}
                                        setEmail={this.props.setEmail}
                                        showRegisterSuccess={this.props.showRegisterSuccess}
                                        showRegisterFailed={this.props.showRegisterFailed}
                                        registered={this.props.registered}
                                        />
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-12">
                                    <h3 className="g-info__heading">Register your Domains (optional)</h3>
                                    <AddDomains domains={this.props.domains} updateDomains={this.props.updateDomains} />
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </main>
        );
    }
}