import React, { Component } from 'react';
import { Message } from '../common/Message';

export class Register extends Component {
    displayName = Register.name;

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const { setEmail, registered } = this.props;
        if (!registered)
            setEmail(event.target.value);
    }

    consentToProductUpdates = (event) => {
        const { consentToProductUpdates } = this.props;
        consentToProductUpdates(event.target.checked);
    }

    consentToMarketing = (event) => {
        const { consentToMarketing } = this.props;
        consentToMarketing(event.target.checked);
    }

    register = () => {
        const { register, validateDetails } = this.props;
        if (validateDetails())
            register();
    }

    render() {
        var receiveMarketingMessages = this.props.receiveMarketingMessages;
        var receiveProductUpdates = this.props.receiveProductUpdates;

        return (
            <article className="c-card ">
                <p className="g-info__description">Register your email address to receive regular product updates, support and tips on how to make best use of your 51Degrees Cloud configuration.</p>
                <p>I want to receive:</p>
                <span className="c-select  c-select--stacked">
                    <input type="checkbox" className="c-select__input is-selectable" id="maintenanceCheck" data-property="" onChange={this.consentToProductUpdates} checked={receiveProductUpdates} disabled={this.props.registered}/>
                    <label className="c-select__label" htmlFor="maintenanceCheck">
                        <span className="c-select__text">Product and maintenance updates</span>
                    </label>
                </span>
                <span className="c-select  c-select--stacked">
                    <input type="checkbox" className="c-select__input is-selectable" id="customCheck" data-property="" onChange={this.consentToMarketing} checked={receiveMarketingMessages} disabled={this.props.registered}/>
                    <label className="c-select__label" htmlFor="customCheck">
                        <span className="c-select__text">Marketing emails and information about our new products</span>
                    </label>
                </span>
                <div className="b-form-group">
                    <label className="b-label" htmlFor="email">Enter your email address</label>
                    <div className="b-input-group">
                        <input type="text" className="b-input" id="inputemail" placeholder="e.g. john_smith@email.com" aria-label="" value={this.props.clientEmail} onChange={this.handleChange} disabled={this.props.registered}/>
                        {this.props.register !== undefined ? <button className="b-btn" onClick={this.register}>Register</button> : null}
                    </div>
                </div>
                {this.props.showRegisterSuccess === true ? (
                    <Message message="Registration successful" />
                ) : ( "" )}
                {this.props.showRegisterFailed === true ? (
                    <Message message="Registration failed" />
                ) : ( "" )}
            </article>
        );
    }
}