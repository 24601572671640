import React, { Component } from 'react';

export class ConfigureNav extends Component {
    displayName = ConfigureNav.name;

    constructor(props) {
        super(props);
    }

    next = (e) => {
        const { next, selectedProperties } = this.props;
        if (selectedProperties.length > 0) {
            next(e);
        } else {
            alert('Please select at least one property.');
        }
    }

    render() {
        return (
            <div className="g-footer__navigation">
                <div className="g-footer__next">
                    <a href="#" className="b-btn b-btn--large" onClick={this.next}><em>Next step:</em>&nbsp; <strong>Details</strong><img src="/images/icon-arrow-right-white.svg" className="b-btn__icon b-btn__icon--after" alt="Next" /></a>
                </div>
            </div>
        );
    }
}