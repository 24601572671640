import React, { Component } from 'react';
import { Property } from './Property';

export class Category extends Component {
    displayName = this.props.category.Name;

    constructor(props) {
        super(props);

        this.state = {
        };
    }
    
    _handleClick = (e) => {
        e.preventDefault();
        const { category, onClick } = this.props;
        var selected = {
            category: category.Id,
            property: ''
        };
        onClick(selected);
    }

    myCallBack = (item) => {
        const { category, onClick } = this.props;
        var selected = {
            category: category.Id,
            property: item
        };
        onClick(selected);
    }

    back = () => {
        const { onClick } = this.props;
        var selected = {
            category: '',
            property: ''
        };
        onClick(selected);
    }

    render() {
        var selectedProperties = [...this.props.selectedProperties];
        var activeProperty = this.props.activeProperty;
        var selectedVendors = this.props.selectedVendors;
        var category = this.props.category;

        let numSelected = 0;

        let properties = [];

        this.props.properties.map((prop) => {
            if (prop.CategoryIds.indexOf(category.Id) !== -1 && properties.indexOf(prop.Name) === -1) {
                properties.push(prop);
            }
            return undefined;
        });

        let numprops = properties.filter(prop => selectedVendors.indexOf(prop.VendorId) !== -1).length;

        let propertyElements = properties.map((property, i) => {
            let _checked = selectedProperties.indexOf(property.Id) > -1;
            if (_checked)
                numSelected++;
            if (this.props.active && selectedVendors.indexOf(property.VendorId) !== -1) {
                let _active = false;
                if (activeProperty === property.Id) {
                    _active = true;
                }
                return (
                    <Property
                        key={i}
                        property={property}
                        category={category}
                        examples={this.props.examples}
                        vendors={this.props.vendors}
                        availableProperties={this.props.availableProperties}
                        onClick={this.myCallBack.bind(this)}
                        onCheck={this.props.onCheck}
                        checked={_checked}
                        active={_active}
                    />);
            }
            return undefined;
        });

        return (
            <li className={`g-panel__item ${this.props.active ? "is-active" : ""}`}>
                <a href="#" className={`c-category__link is-revealable ${this.props.active ? "is-active" : ""}`} onClick={this._handleClick}>
                    { this.displayName }
                    <span className="c-category__count c-category__count--selected" style={numSelected > 0 ? { display: 'inline' } : { display: 'none' }}>{numSelected}</span> <span className="c-category__count">{numprops}</span>
                </a>
                <div className={`g-panel g-panel--25  ${this.props.active ? "is-active" : ""}`}>
                    <header className="c-category-header"><a className="c-category-header__link is-reversable" onClick={this.back}>{this.props.component.Name}</a> / {this.displayName}</header>
                    <ul className="g-panel__list">
                        { propertyElements }
                    </ul>
                </div>
            </li>
        );
    }
}