import React, { Component } from 'react';

import { Category } from './Category';

export class Group extends Component {
    displayName = this.props.component.Name;

    constructor(props) {
        super(props);

        this.state = {
        };
    }
    
    categories = []

    _handleClick = (e) => {
        e.preventDefault();
        const { component, onClick } = this.props;
        var active = {
            component: component.Id,
            category: '',
            property: ''
        };
        onClick(active);
    }

    myCallBack = (item) => {
        const { component, onClick } = this.props;
        var active = {
            component: component.Id,
            category: item.category,
            property: item.property
        };
        onClick(active);
    }

    back = () => {
        const { onClick } = this.props;
        var active = {
            component: '',
            category: '',
            property: ''
        };
        onClick(active);
    }

    render() {
        var active = this.props.active;
        var selectedProperties = [...this.props.selectedProperties];
        var selectedVendors = [...this.props.selectedVendors];
        let activeCategory = this.props.activeCategory;

        let properties = [];

        this.props.properties.map((prop) => {
            if (prop.ComponentId === this.props.component.Id && properties.indexOf(prop.Name) === -1) {
                properties.push(prop);
            }
            return undefined;
        });

        let numprops = properties.filter(prop => selectedVendors.indexOf(prop.VendorId) !== -1).length;

        let numSelected = 0;
        selectedProperties.map((prop) => {
            if (properties.find((property) => {
                return property.Id === prop;
                return undefined;
            }))
            numSelected++;
            return undefined;
        });

        this.categories = this.props.categories.map((category, i) => {
            if (active && this.props.component.Id === category.ComponentId && selectedVendors.some(s => category.VendorIds.indexOf(s) !== -1)) {
                let _active = false;
                if (activeCategory === category.Id) {
                    _active = true;
                }
                return (
                    <Category
                        key={i}
                        component={this.props.component}
                        category={category}
                        properties={properties}
                        examples={this.props.examples}
                        vendors={this.props.vendors}
                        onClick={this.myCallBack.bind(this)}
                        onCheck={this.props.onCheck}
                        availableProperties={this.props.availableProperties}
                        selectedProperties={selectedProperties}
                        selectedVendors={this.props.selectedVendors}
                        active={_active}
                        activeProperty={this.props.activeProperty}
                    />);
            }
        });

        return (
            <li className={`g-panel__item ${this.props.active ? "is-active" : ""}`}>
                <a href="#" className={`c-category__link is-revealable ${this.props.active ? "is-active" : ""}`} onClick={this._handleClick}>
                    {this.displayName} <span className="c-category__count c-category__count--selected" style={numSelected > 0 ? { display: 'inline' } : { display: 'none' }}>{numSelected}</span> <span className="c-category__count">{numprops}</span>
                </a>
                <div className={`g-panel g-panel--25 ${this.props.active ? "is-active" : ""}`}  >
                    <header className="c-category-header"><a className="c-category-header__link is-reversable" onClick={this.back}>Home</a> / {this.displayName}</header>
                    <ul className="g-panel__list">
                        { this.categories }
                    </ul>
                </div>
            </li>
        );
    }
}
