import React, { Component } from 'react';
import { DotNet } from './DotNet';
import { Java } from './Java';
import { Node } from './Node';
import { PHP } from './PHP';
import { Python } from './Python';

export class ServerSideImplementation extends Component {
    displayName = ServerSideImplementation.name;

    constructor(props) {
        super(props);
        this.state = {
            active: this.props.active
        };

        this.dotNetRef = React.createRef();
        this.javaRef = React.createRef();
        this.nodeRef = React.createRef();
        this.phpRef = React.createRef();
        this.pythonRef = React.createRef();
    }

    newPackage = (copy, packageName, packageString, packageKey) => {
        return (
            <div key={packageKey}>
                <div className="c-code">
                    <header className="c-code__header">
                        <h2 className="c-code__heading">{packageName}</h2>
                        <a href="#" className="c-copy-clipboard" onClick={(e) => { e.preventDefault(); copy(packageString); }}><img src="/images/icon-clipboard.svg" alt="Copy to clipboard" /></a>
                    </header>
                    <code className="c-code__block">
                        {packageString}
                    </code>
                </div>
                <br />
            </div>
        );
    }

    addToArray = (evidenceList, evidence) => {
        if (evidenceList.indexOf(evidence) === -1) {
            evidenceList.push(evidence);
        }
    }

    handleClick = (e) => {
        e.preventDefault();
        const { handleClick } = this.props;
        handleClick('');
    };

    scrollToTop = () => {
        if (this.refs.scroller) {
            this.refs.scroller.scrollTop = 0;
        }
    };

    scrollToMyRef = (e, ref) => {
        e.preventDefault();
        ref.current.scrollIntoView();
    }

    render() {
        let classes = "g-panel g-panel--75";

        if (this.props.active) {
            classes += " is-active";
        }

        let dataKeys = [];

        this.props.selectedProperties
            // filter out other hardware.Profiles properties if the 'hardare.Profiles' property is not in the selected list.
            .filter(prop => this.props.selectedProperties.indexOf("hardware.Profiles") !== -1 || prop.startsWith("hardware.Profiles") === false)
            .forEach(prop => {
            var dataKey = prop.substring(0, prop.lastIndexOf('.'));
            if (dataKeys.indexOf(dataKey) === -1) {
                dataKeys.push(dataKey);
            }
        });

        let dataKeyOrder = [
            "device",
            "hardware.Profiles",
            "ip",
            "location",
            "location_consolidated",
            "local_countryname",
            "neighbouring_countries",
            "country_list"
        ];

        dataKeys.sort(function (a, b) {
            return dataKeyOrder.indexOf(a) - dataKeyOrder.indexOf(b);
        })

        return (
            <div className={classes}>
                <header className="c-category-header">
                    <a href="#" className="c-category-header__link is-reversable" onClick={this.handleClick}>Back</a> / Server side
                </header>

                <article className="g-info" ref="scroller">
                    <h2 className="g-info__title">Server side</h2>
                    <nav className="c-sub-nav">
                        <h3 className="c-sub-nav__heading">On this page</h3>
                        <p>Understand how physical screen size, device price and page performance impact conversion and goal completion.</p>
                        <ul className="c-sub-nav__list">
                            <li className="c-sub-nav__link"><a href="#dotnet" className="c-sub-nav__item" onClick={(e) => { this.scrollToMyRef(e, this.dotNetRef); }}>.NET</a></li>
                            <li className="c-sub-nav__link"><a href="#java" className="c-sub-nav__item" onClick={(e) => { this.scrollToMyRef(e, this.javaRef); }}>Java</a></li>
                            <li className="c-sub-nav__link"><a href="#node" className="c-sub-nav__item" onClick={(e) => { this.scrollToMyRef(e, this.nodeRef); }}>Node.js</a></li>
                            <li className="c-sub-nav__link"><a href="#php" className="c-sub-nav__item" onClick={(e) => { this.scrollToMyRef(e, this.phpRef); }}>PHP</a></li>
                            <li className="c-sub-nav__link"><a href="#python" className="c-sub-nav__item" onClick={(e) => { this.scrollToMyRef(e, this.pythonRef); }}>Python</a></li>
                        </ul>
                    </nav>
                    <DotNet
                        refProp={this.dotNetRef}
                        id="dotnet"
                        properties={this.props.properties}
                        selectedProperties={this.props.selectedProperties}
                        hiddenProperties={this.props.hiddenProperties}
                        resource={this.props.resource}
                        examples={this.props.examples}
                        newPackage={this.newPackage}
                        addToArray={this.addToArray}
                        dataKeys={dataKeys}
                    />
                    <Java
                        refProp={this.javaRef} 
                        id="java"
                        properties={this.props.properties}
                        selectedProperties={this.props.selectedProperties}
                        hiddenProperties={this.props.hiddenProperties}
                        resource={this.props.resource}
                        examples={this.props.examples}
                        newPackage={this.newPackage}
                        addToArray={this.addToArray}
                        dataKeys={dataKeys}
                    />
                    <Node
                        refProp={this.nodeRef}
                        id="node"
                        properties={this.props.properties}
                        selectedProperties={this.props.selectedProperties}
                        hiddenProperties={this.props.hiddenProperties}
                        resource={this.props.resource}
                        examples={this.props.examples}
                        newPackage={this.newPackage}
                        addToArray={this.addToArray}
                        dataKeys={dataKeys}
                    />
                    <PHP
                        refProp={this.phpRef} 
                        id="php"
                        properties={this.props.properties}
                        selectedProperties={this.props.selectedProperties}
                        hiddenProperties={this.props.hiddenProperties}
                        resource={this.props.resource}
                        examples={this.props.examples}
                        newPackage={this.newPackage}
                        addToArray={this.addToArray}
                        dataKeys={dataKeys}
                    />
                    <Python
                        refProp={this.pythonRef}
                        id="python"
                        properties={this.props.properties}
                        selectedProperties={this.props.selectedProperties}
                        hiddenProperties={this.props.hiddenProperties}
                        resource={this.props.resource}
                        examples={this.props.examples}
                        newPackage={this.newPackage}
                        addToArray={this.addToArray}
                        dataKeys={dataKeys}
                    />
                    <h2 className="g-info__subheading">Benefits of Server side implementations</h2>

                    <div className="g-info__description">
                        <p><em>Richer Context:</em> Understand how physical screen size, device price and page performance impact conversion and goal completion.</p>
                        <p><em>User Experience:</em> Discover insights, test, and learn to continually improve with richer data from Enhanced Analytics.</p>
                        <p><em>Collaboration:</em> Easy to integrate with existing business intelligence tools including Google Analytics, Omniture, Coremetrics and Webtrends.</p>
                    </div>
                    <a className="c-top-link hide" href="#" id="js-top" onClick={(e) => { e.preventDefault(); this.scrollToTop(); }}>
                        <img src="/images/back-to-top.svg" alt="Back to top" className="c-top-link__image" />
                        <span className="c-top-link__label">Back to top</span>
                    </a>
                </article>
            </div>);
    }
}
