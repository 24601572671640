import React, { Component } from 'react';
import { AnalyticsImplementation } from './AnalyticsImplementation';

export class Analytics extends Component {
    displayName = Analytics.name;

    constructor(props) {
        super(props);
    }

    name = 'Analytics';

    handleClick = (e) => {
        e.preventDefault();
        const { handleClick } = this.props;
        handleClick(this.name);
    }

    render() {
        let _active = this.props.active === this.name;

        let classes = "c-category__link is-revealable";

        if (_active)
            classes += " is-active";

        return (
            <li key="analytics" className="g-panel__item">
                <a href="#" className={classes} onClick={this.handleClick}>Analytics</a>
                <AnalyticsImplementation
                    active={_active}
                    properties={this.props.properties}
                    selectedProperties={this.props.selectedProperties}
                    hiddenProperties={this.props.hiddenProperties}
                    resource={this.props.resource.url}
                    handleClick={this.props.handleClick}
                />
            </li>
        );
    }
}

