import React, { Component } from 'react';

import '../../css/spinner.css';

import { SelectableCard } from './SelectableCard';
import { LicenseKeyList } from './LicenseKeyList';

export class LicenseKey extends Component {
    displayName = LicenseKey.name;

    constructor(props) {
        super(props);

        this.state = {
            licenseKey: ''
        };
    }

    componentDidMount() {
        this.setState({ loading: this.props.productKeys.length });
    }

    updateLicenseKey = (event) => {
        this.setState({ licenseKey: event.target.value });
    }

    addKey = () => {
        const { addKey, loadingLicense, setLoading } = this.props;
        let key = this.state.licenseKey
        if (key !== '') {
            addKey(key);
        }
        var loading = loadingLicense;
        this.setState({ licenseKey: '' });
        setLoading(loading + 1);
    }

    render() {
        var vendors = this.props.requriedVendors;

        return (
            <SelectableCard
                id={this.props.id}
                selected={this.props.selected}
                disabled={this.props.disabled}
                onSelected={this.props.onSelected}>
                <div className="b-form-group b-form-group--with-icon">
                    <img src={this.props.fiftyone.iconUrl} className="b-icon" alt="51Degrees" />
                    <div className="b-input-group">
                        <input type="text" className="b-input b-input--block" id="input" placeholder={`Enter your 51Degrees license key`} onChange={this.updateLicenseKey} value={this.state.licenseKey} disabled={this.props.disabled} />
                        <button className="b-btn" onClick={this.addKey} disabled={this.props.disabled}>Add&nbsp;Key</button>
                    </div>
                    {vendors.length > 0 ? <p className="">Required: {vendors.map((v, i) => (<span key={i} style={v.available ? {"text-decoration": "line-through"}: null}>{v.name}{vendors.length-1 > i ? ", " : ""}</span>))}</p> : null}
                </div>
                <LicenseKeyList
                    productKeys={this.props.productKeys}
                />
                {this.props.loadingLicense > this.props.productKeys.length ? <div className="icon-container">
                    <i className="loader"></i>
                </div> : null}
                {vendors.find(v => v.addon === true) ? <p>* Add-On: requires a subscription to 51Degrees.</p> : null}
            </SelectableCard>
        );
    }
}