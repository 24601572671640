import React, { Component } from 'react';
import { Register } from '../details/Register';

export class Share extends Component {
    displayName = Share.name;

    constructor(props) {
        super(props);

        this.state = {
            title: 'Real-time data Superpowers',
            lines: {},
            default: 'Nice #opensource APIs for #devicedetection from @51Degrees. Just pick your properties and you’re good to go! %LINK%',
            sharingLink: '',
            alreadyRegistered: this.props.registered
        };

        let properties = this.props.selectedProperties;


        fetch('/api/configuration/addconfig', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(properties),
        })
        .then(response => response.text())
        .then(data => this.setState({ sharingLink: window.location.protocol + '//' + window.location.host + '/' + data }));

        // Fetch the social media sharing messages.
        fetch('/api/data/sociallines')
            .then(response => response.json())
            .then(data => this.setState({ lines: data }));
    }

    /**
     * Get a message from the list of social media sharing messages.
     * @id the id of the platform to get messages for, can be blank.
     */
    getText = (id) => {
        let lines = this.state.lines;
        let text = this.state.default;
        let url = this.state.sharingLink;

        if (Object.keys(lines).length !== 0) {
            if (lines.hasOwnProperty(id) !== -1 &&
                lines[id].length > 0) {
                text = lines[id][Math.floor(Math.random() * lines[id].length)];
            } else if (lines.hasOwnProperty("default") !== -1 &&
                lines["default"].length > 0) {
                text = lines["default"][Math.floor(Math.random() * lines["default"].length)];
            } 
        }

        text = text.replace(/%LINK%/g, url);

        return text;
    }

    render() {
        let url = encodeURI(this.state.sharingLink);
        let title = encodeURI(this.state.title);

        let twitterText = encodeURIComponent(this.getText("twitter"));
        let faceBookText = encodeURIComponent(this.getText("facebook"));
        let emailText = encodeURIComponent(this.getText("email"));

        return (
            <main className="g-main">
                <div className="g-panel g-panel--100">
                    <article className="g-info">
                        <h2 className="g-info__title">Congratulations, you are all set!</h2>
                        <p className="g-info__description">See the V4 cloud reference documentation: <a href="https://cloud.51degrees.com/api-docs/index.html?urls.primaryName=51Degrees%20Cloud%20V4%20(Generated)" target="blank">cloud.51degrees.com/api-docs</a></p>
                        <h3 className="g-info__heading">Spread the word</h3>
                        <article className="c-card ">
                            <h4 className="c-card__heading">Share your new 51Degrees Cloud configuration with your colleagues and community. Simply use this URL containing your collection of properties. (Don’t worry – this won’t include any sensitive information such as API keys, domains or email addresses.)</h4>
                            <div className="b-form-group">
                                <input type="text" className="b-input b-input--block" id="input" value={url} readOnly aria-label="Your shareable URL" />
                            </div>
                            <p>Share via:</p>
                            <div className="g-footer__social-links b-mb-2">
                                <a className="c-option__vendor b-mb-2" href={`https://twitter.com/intent/tweet?text=${twitterText}&url=${url}`} target="blank"><img className="g-footer__social-icon" src="/images/icon-twitter.svg" alt="Twitter"/></a>
                                <a className="c-option__vendor b-mb-2" href={`https://www.facebook.com/dialog/share?app_id=728770251342647&display=popup&href=${url}&redirect_uri=${url}`} target="blank"><img className="g-footer__social-icon" src="/images/icon-facebook.svg" alt="Facebook"/></a>
                                <a className="c-option__vendor b-mb-2" href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`} target="blank"><img className="g-footer__social-icon" src="/images/icon-linkedin.svg" alt="LinkedIn" /></a>
                                <a className="c-option__vendor b-mb-2" href={`mailto:?subject=${title}&body=${emailText}`} target="blank"><img className="g-footer__social-icon" src="/images/icon-email.svg" alt="Email" /></a>
                            </div>
                        </article>
                        {this.props.registered ? 
                            this.state.alreadyRegistered ? null :
                                <React.Fragment>
                                    <h3 className="g-info__heading">Thanks for registering!</h3>
                                    <article className="c-card ">
                                        <h4 className="c-card__heading">You will receive an email containing all the details you need to get going.</h4>
                                    </article>
                                </React.Fragment>
                            :
                            <React.Fragment>
                                <h3 className="g-info__heading">Keep in touch (optional)</h3>
                                <Register clientEmail={this.props.clientEmail}
                                    receiveMarketingMessages={this.props.receiveMarketingMessages}
                                    receiveProductUpdates={this.props.receiveProductUpdates}
                                    consentToMarketing={this.props.consentToMarketing}
                                    consentToProductUpdates={this.props.consentToProductUpdates}
                                    setEmail={this.props.setEmail}
                                    register={this.props.register}
                                    validateDetails={this.props.validateDetails}
                                    showRegisterSuccess={this.props.showRegisterSuccess}
                                    showRegisterFailed={this.props.showRegisterFailed}
                                    />
                            </React.Fragment>
                        }
                    </article>
                </div>
            </main>
        );
    }
}