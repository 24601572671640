import React, { Component } from 'react';

export class BasketItem extends Component {
    displayName = BasketItem.name;

    constructor(props) {
        super(props);
    }

    _remove = (e) => {
        e.preventDefault();
        const { property, onClick } = this.props;
        onClick(property.Id);
    }

    // Returns true if the property is billale, otherwise false.
    getIsBillable(property) {
        const availableProperties = this.props.availableProperties;
        var isBillable = true;
        if (availableProperties.Products[property.VendorId]) {
            const freeProperties =
                availableProperties.Products[property.VendorId].Properties;
            isBillable = !freeProperties.find((prop) =>
                property.VendorId + "." + prop.Name === property.Id);
        }
        return isBillable;
    }

    render() {
        const property = this.props.property;
        const isBillable = this.getIsBillable(property);
        const vendor = this.props.vendors.find((vendor) => {
            return vendor.map === property.VendorId;
        });
        return (
            <li data-id={property.Id} className='g-basket__item'>
                <a href="#" className='g-basket__remove' onClick={this._remove}>{property.Name}<span className='c-option__additional'>
                    <span className='c-option__vendor'><img src={vendor.iconUrl} alt={vendor.name} width="13" height="13"/></span>
                    {isBillable ? <span className="c-option__billable"><span>Billable</span></span> : null}
                </span>
                </a>
            </li>
        );
    }
}