export const matomoTrackSection = (section, sectionUpdated, sectionName, setState) => {
    // two states in this.state. This check ensures we track the change 
    // only when moving from one dsection to another
    if (section != sectionUpdated) {
        window._paq?.push(['setDocumentTitle', `${sectionName} - My Website`]);
        window._paq?.push(['trackPageView']);
        setState({ sectionUpdated: section })
    }
}

export const matomoTrackCustomEvent = (actionName, actionData) => {
    window._paq?.push(['trackEvent', 'CustomActions', actionName, JSON.stringify(actionData)]);
}